import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../global/LoginAuth';
import { getCityConfig, DEFAULT_CITY } from '../../config/config';
import '../../styling/universal/global.css';
import '../../styling/universal/forms.css';
import { RxCross2 } from "react-icons/rx";
import { IoArrowForwardCircle } from "react-icons/io5";
const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { login } = useAuth();
    const getCityFromEmail = (email) => {
        const domain = email.split('@')[1];
        if (domain?.includes('helsinki'))
            return 'helsinki';
        if (domain?.includes('turku'))
            return 'turku';
        return DEFAULT_CITY;
    };
    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage('');
        const userCity = getCityFromEmail(email);
        const cityConfig = getCityConfig(userCity);
        try {
            const response = await fetch(cityConfig.apiEndpoints.LOGIN, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ email, password }),
            });
            const data = await response.json();
            if (data.success) {
                login(data.token, data.role, data.userID, data.email);
                const username = data.email.split('@')[0].replace('.', '');
                if (data.role === 'teacher') {
                    navigate(`/${userCity}/${username}/opetus`);
                }
                else if (data.role === 'student') {
                    try {
                        const userDetailsResponse = await fetch(`${cityConfig.apiEndpoints.USER}/${data.userID}`, {
                            headers: {
                                'Authorization': `Bearer ${data.token}`
                            },
                            credentials: 'include'
                        });
                        const userDetails = await userDetailsResponse.json();
                        if (userDetails.success) {
                            const roomResponse = await fetch(cityConfig.apiEndpoints.ROOMS, {
                                headers: {
                                    'Authorization': `Bearer ${data.token}`
                                },
                                credentials: 'include'
                            });
                            const rooms = await roomResponse.json();
                            const reservedRoom = rooms.find((room) => room.reservedBy === data.email);
                            navigate(`/${userCity}/${username}/${reservedRoom ? 'loosi' : 'kartta'}`);
                        }
                        else {
                            navigate(`/${userCity}/${username}/kartta`);
                        }
                    }
                    catch (error) {
                        console.error('Error fetching additional data:', error);
                        navigate(`/${userCity}/${username}/kartta`);
                    }
                }
            }
            else {
                setMessage(data.message || 'Invalid credentials');
            }
        }
        catch (error) {
            console.error('Login request failed:', error);
            setMessage('Network error or server unavailable');
        }
        finally {
            setIsLoading(false);
        }
    };
    const handleClose = () => {
        navigate('/');
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin(e);
        }
    };
    return (_jsx("div", { className: "formUI", children: _jsxs("div", { className: "formbox", children: [_jsx("button", { className: "x-button", onClick: handleClose, "aria-label": "Close form", children: _jsx(RxCross2, {}) }), _jsx("div", { className: "formtitle", children: _jsx("span", { className: 'title_text', children: "login" }) }), _jsxs("div", { className: "form_container", children: [_jsx("input", { className: "header_input", type: "email", placeholder: "Email", value: email, onChange: (e) => setEmail(e.target.value), onKeyPress: handleKeyPress, disabled: isLoading, autoComplete: "username", "aria-label": "Email input" }), _jsx("input", { className: "header_input", type: "password", placeholder: "Password", value: password, onChange: (e) => setPassword(e.target.value), onKeyPress: handleKeyPress, disabled: isLoading, autoComplete: "current-password", "aria-label": "Password input" })] }), _jsxs("div", { className: 'formbutton', children: [_jsx("button", { className: "menubutton_dark", onClick: handleLogin, disabled: isLoading, "aria-label": "Login button", children: _jsx(IoArrowForwardCircle, {}) }), message && _jsx("p", { className: "error-message", role: "alert", children: message }), isLoading && _jsx("p", { children: "Loading..." })] })] }) }));
};
export default LoginForm;
