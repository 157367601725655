export const CITIES = {
    helsinki: {
        name: 'helsinki',
        timezone: 'Europe/Helsinki',
        subjects: [
            "perushoito",
            "kariologia ja endodontia",
            "parodontologia",
            "pedodontia",
            "protetiikka",
            "purentafysiologia",
            "päivystys",
            "oikomishoito",
            "kirurgia",
            "limakalvo"
        ],
        apiEndpoints: {
            LOGIN: '/database/users/users_helsinki/login',
            REGISTER: '/database/users/users_helsinki/register',
            USER: '/database/users/users_helsinki/user',
            ROOMS: '/database/rooms_helsinki',
            ROOMS_UPDATE: '/database/rooms_helsinki/update',
            RELEASES: '/database/releases_helsinki',
            ROOM_LAYOUT: '/Helsinki/RoomLayout'
        },
        roomLayout: {
            H: Array.from({ length: 18 }, (_, i) => `H${i + 1}`),
            S: Array.from({ length: 14 }, (_, i) => `S${18 + i + 1}`),
            V: Array.from({ length: 10 }, (_, i) => `V${32 + i + 1}`)
        }
    },
    turku: {
        name: 'turku',
        timezone: 'Europe/Helsinki',
        subjects: [
            "paikkaus",
            "kariologia",
            "paro",
            "pedo",
            "protetiikka",
            "purentafysiologia",
            "päivystys",
            "oikki",
            "kirra",
            "limakalvo"
        ],
        apiEndpoints: {
            LOGIN: '/database/users/users_turku/login',
            REGISTER: '/database/users/users_turku/register',
            USER: '/database/users/users_turku/user',
            ROOMS: '/database/rooms_turku',
            ROOMS_UPDATE: '/database/rooms_turku/update',
            RELEASES: '/database/releases_turku',
            ROOM_LAYOUT: '/Turku/RoomLayout'
        },
        roomLayout: {
            H: Array.from({ length: 18 }, (_, i) => `H${i + 1}`),
            S: Array.from({ length: 14 }, (_, i) => `S${18 + i + 1}`),
            V: Array.from({ length: 10 }, (_, i) => `V${32 + i + 1}`)
        }
    }
};
export const DEFAULT_CITY = 'helsinki';
