// myklinik-frontend/src/components/global/Time.tsx
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
const helsinkiTimeZone = 'Europe/Helsinki';
export const getCurrentHelsinkiTime = () => {
    return utcToZonedTime(new Date(), helsinkiTimeZone);
};
export const formatTime = (date) => {
    const helsinkiDate = typeof date === 'string' || typeof date === 'number'
        ? utcToZonedTime(new Date(date), helsinkiTimeZone)
        : utcToZonedTime(date, helsinkiTimeZone);
    return format(helsinkiDate, 'HH:mm');
};
export const formatNotificationTime = (notificationTime) => {
    if (!notificationTime)
        return '';
    const notificationDate = new Date(notificationTime);
    const helsinkiNotificationDate = utcToZonedTime(notificationDate, helsinkiTimeZone);
    const currentTime = getCurrentHelsinkiTime();
    const diffMinutes = Math.max(0, Math.floor((currentTime.getTime() - helsinkiNotificationDate.getTime()) / 60000));
    return `${diffMinutes} min`;
};
