import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// myklinik-frontend/src/components/global/whiteboard.tsx
import { useState, useEffect, useCallback } from 'react';
import '../../styling/universal/global.css';
import '../../styling/universal/whiteboard.css';
import { IoCloseCircleSharp } from "react-icons/io5";
const Whiteboard = ({ isOpen, onClose, userType }) => {
    const [releases, setReleases] = useState([]);
    const [organizedReleases, setOrganizedReleases] = useState({});
    const [sortedSubjects, setSortedSubjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const fetchReleases = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`http://0.0.0.0:4010/database/releases_helsinki`);
            if (!response.ok)
                throw new Error('Failed to fetch releases');
            const data = await response.json();
            console.log("Raw data received:", data); // Log raw data
            const releaseData = userType === 'student' ? data.ReleaseStudent : data.ReleaseTeacher;
            console.log(`Filtered ${userType} releases:`, releaseData); // Log filtered data
            setReleases(releaseData || []);
        }
        catch (error) {
            console.error('Error fetching releases:', error);
            setError('Failed to load releases. Please try again.');
        }
        finally {
            setLoading(false);
        }
    }, [userType]);
    useEffect(() => {
        if (isOpen) {
            fetchReleases();
        }
    }, [isOpen, fetchReleases]);
    useEffect(() => {
        const organized = releases.reduce((acc, release) => {
            if (!acc[release.subject]) {
                acc[release.subject] = [];
            }
            acc[release.subject].push(release.content);
            return acc;
        }, {});
        const subjects = Object.keys(organized);
        const sorted = subjects.sort((a, b) => {
            if (a.toLowerCase() === 'yleistä')
                return -1;
            if (b.toLowerCase() === 'yleistä')
                return 1;
            return a.localeCompare(b, 'fi');
        });
        setOrganizedReleases(organized);
        setSortedSubjects(sorted);
    }, [releases]);
    if (!isOpen)
        return null;
    return (_jsx("div", { className: "whiteboard-overlay", children: _jsxs("div", { className: "whiteboard", children: [_jsx("div", { className: "titlebox", children: _jsx("h2", { className: "title_text", children: "valkotaulu" }) }), _jsx("div", { className: "whiteboard-content", children: loading ? (_jsx("p", { children: "Loading releases..." })) : error ? (_jsx("p", { className: "error", children: error })) : (_jsxs(_Fragment, { children: [_jsx("h3", { className: 'usr', children: userType === 'student' ? 'opiskelijat' : 'henkilökunta' }), sortedSubjects.map((subject) => (_jsxs("div", { className: "subject_board", children: [_jsx("h3", { children: subject }), organizedReleases[subject].map((content, index) => (_jsx("p", { children: content }, index)))] }, subject)))] })) }), _jsx("div", { className: "lowbox", children: _jsx("button", { className: "close-button", onClick: onClose, children: _jsx(IoCloseCircleSharp, {}) }) })] }) }));
};
export default Whiteboard;
