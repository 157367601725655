import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Navigate, useLocation } from 'react-router-dom';
const PrivateRoute = ({ children, role, checkUser }) => {
    const token = localStorage.getItem('token');
    const userRole = localStorage.getItem('role');
    const userEmail = localStorage.getItem('email');
    const location = useLocation();
    if (!token || (role && userRole !== role)) {
        return _jsx(Navigate, { to: "/login" });
    }
    if (checkUser) {
        const pathname = location.pathname;
        const userNameFromPath = pathname.split('/')[1]; // Extract the username from the path
        const userNameFromEmail = userEmail?.split('@')[0]; // Extract the username part from the email
        if (userNameFromPath !== userNameFromEmail) {
            return _jsx(Navigate, { to: "/unauthorized" }); // Redirect to an unauthorized page or home page
        }
    }
    return _jsx(_Fragment, { children: children });
};
export default PrivateRoute;
