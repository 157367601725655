import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import HeaderTeacher from './HeaderTeacher';
import Chat from '../../global/chat';
import Whiteboard from '../../global/whiteboard';
import Ohjauslista from './ohjauslista';
import Ohjattavat from './ohjattavat';
import { useAllUsersDatabase } from '../../global/FetchUsers';
import { CONFIG, getCityConfig, getEndpoints } from '../../../config/config';
import { getCurrentHelsinkiTime } from '../../global/Time';
import '../../../styling/universal/global.css';
import '../../../styling/universal/mainUI.css';
import '../../../styling/universal/teacherUI.css';
import '../../../styling/universal/chat.css';
import '../../../styling/universal/whiteboard.css';
import { PiSmileyFill, PiSmileyMeltingFill, PiSmileyMehFill } from "react-icons/pi";
const apiRequest = async (endpoint, options = {}) => {
    try {
        const response = await fetch(endpoint, {
            ...options,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                ...options.headers,
            },
            credentials: 'include'
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (CONFIG.DEBUG) {
            console.log(`API Response from ${endpoint}:`, data);
        }
        return data;
    }
    catch (error) {
        if (CONFIG.DEBUG) {
            console.error(`API Error for ${endpoint}:`, error);
        }
        throw error;
    }
};
const TeacherUI = () => {
    const { city } = useParams();
    const [user, setUser] = useState(null);
    const userCity = city;
    const cityConfig = useMemo(() => getCityConfig(userCity), [userCity]);
    const cityEndpoints = useMemo(() => getEndpoints(userCity), [userCity]);
    const socket = useMemo(() => io(CONFIG.SOCKET_URL, {
        transports: ['websocket'],
        reconnectionAttempts: parseInt(process.env.REACT_APP_SOCKET_RECONNECT_ATTEMPTS || '5'),
        reconnectionDelay: parseInt(process.env.REACT_APP_SOCKET_RECONNECT_DELAY || '1000'),
        autoConnect: true,
        auth: { token: localStorage.getItem('token') }
    }), []);
    const [statuses, setStatuses] = useState({});
    const [showRooms, setShowRooms] = useState(true);
    const [currentTime, setCurrentTime] = useState(getCurrentHelsinkiTime());
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedSlotTime, setSelectedSlotTime] = useState(null);
    const [isChatVisible, setIsChatVisible] = useState(false);
    const [unreadMessages, setUnreadMessages] = useState({});
    const [isWhiteboardOpen, setIsWhiteboardOpen] = useState(false);
    const [showReasonHeader, setShowReasonHeader] = useState({});
    const { users: allUsers } = useAllUsersDatabase(userCity);
    const allUsersFront = useMemo(() => {
        return (allUsers || []).map(user => ({
            _id: user._id || '',
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password || '',
            city: user.city || '',
            role: user.role
        }));
    }, [allUsers]);
    // Effects and Event Handlers
    // Timer effect
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(getCurrentHelsinkiTime());
        }, 1000);
        return () => clearInterval(timer);
    }, []);
    // Initial data loading
    useEffect(() => {
        const loadInitialData = async () => {
            const userID = localStorage.getItem('userID');
            if (!userID) {
                alert('User ID not found. Please log in again.');
                return;
            }
            try {
                const userData = await apiRequest(`${cityEndpoints.USER}/${userID}`);
                if (userData.success) {
                    setUser(userData.user);
                }
                await fetchRoomData();
            }
            catch (error) {
                if (CONFIG.DEBUG) {
                    console.error('Error loading initial data:', error);
                }
                alert('Failed to load initial data. Please try again.');
            }
        };
        loadInitialData();
    }, [cityEndpoints]);
    // Socket event handlers
    useEffect(() => {
        const handleRoomStatusUpdate = (updatedStatuses) => {
            setStatuses(prevStatuses => {
                const newStatuses = { ...prevStatuses };
                Object.entries(updatedStatuses).forEach(([room, updatedRoom]) => {
                    if (newStatuses[room]) {
                        newStatuses[room] = {
                            ...newStatuses[room],
                            ...updatedRoom,
                            subjects: updatedRoom.subjects || newStatuses[room].subjects
                        };
                    }
                });
                return newStatuses;
            });
        };
        const handleSocketError = (error) => {
            if (CONFIG.DEBUG) {
                console.error('Socket error:', error);
            }
        };
        socket.on(`roomStatusUpdate${userCity?.charAt(0).toUpperCase()}${userCity?.slice(1)}`, handleRoomStatusUpdate);
        socket.on('error', handleSocketError);
        socket.on('connect_error', handleSocketError);
        return () => {
            socket.off(`roomStatusUpdate${userCity?.charAt(0).toUpperCase()}${userCity?.slice(1)}`, handleRoomStatusUpdate);
            socket.off('error', handleSocketError);
            socket.off('connect_error', handleSocketError);
        };
    }, [userCity, socket]);
    // Chat-related socket events
    useEffect(() => {
        const handleChatUpdate = (updatedRoom) => {
            setStatuses(prevStatuses => ({
                ...prevStatuses,
                [updatedRoom.room]: updatedRoom
            }));
        };
        const handleMessageReceived = (updatedRoom) => {
            handleChatUpdate(updatedRoom);
            updateUnreadMessages(updatedRoom);
        };
        socket.on('chatUpdate', handleChatUpdate);
        socket.on('messageReceived', handleMessageReceived);
        return () => {
            socket.off('chatUpdate', handleChatUpdate);
            socket.off('messageReceived', handleMessageReceived);
        };
    }, [socket]);
    // Utility functions and Room Handlers
    const updateUnreadMessages = (updatedRoom) => {
        setUnreadMessages(prev => {
            const newUnreadMessages = { ...prev };
            updatedRoom.subjects.forEach(subject => {
                subject.subjectSpecificTime.forEach(sst => {
                    const unreadCount = sst.messageStudent.filter(msg => msg.status === 'unopened').length;
                    if (unreadCount > 0) {
                        if (!newUnreadMessages[updatedRoom.room]) {
                            newUnreadMessages[updatedRoom.room] = {};
                        }
                        if (!newUnreadMessages[updatedRoom.room][subject.name]) {
                            newUnreadMessages[updatedRoom.room][subject.name] = {};
                        }
                        newUnreadMessages[updatedRoom.room][subject.name][sst.slotTime.toString()] = unreadCount;
                    }
                });
            });
            return newUnreadMessages;
        });
    };
    const fetchRoomData = async () => {
        try {
            const roomStatuses = await apiRequest(cityEndpoints.ROOMS);
            setStatuses(prevStatuses => {
                const updatedStatuses = {};
                roomStatuses.forEach((roomStatus) => {
                    updatedStatuses[roomStatus.room] = roomStatus;
                });
                return { ...prevStatuses, ...updatedStatuses };
            });
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error fetching room data:', error);
            }
            throw error;
        }
    };
    const handleRoomStatusUpdate = async (updatedRoom) => {
        try {
            await apiRequest(cityEndpoints.ROOMS_UPDATE, {
                method: 'POST',
                body: JSON.stringify(updatedRoom)
            });
            socket.emit(`updateRoomStatus${userCity?.charAt(0).toUpperCase()}${userCity?.slice(1)}`, { [updatedRoom.room]: updatedRoom });
            setStatuses(prev => ({
                ...prev,
                [updatedRoom.room]: updatedRoom
            }));
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error updating room status:', error);
            }
            throw error;
        }
    };
    // Room Action handlers
    const handleJoinRoom = async (room, subjectName, slotTime) => {
        if (!user || !statuses[room])
            return;
        try {
            const updatedRoom = { ...statuses[room] };
            const subjectIndex = updatedRoom.subjects.findIndex(s => s.name === subjectName);
            if (subjectIndex !== -1) {
                const sstIndex = updatedRoom.subjects[subjectIndex].subjectSpecificTime.findIndex(sst => {
                    const sstSlotTime = new Date(sst.slotTime);
                    return sstSlotTime.getTime() === slotTime.getTime();
                });
                if (sstIndex !== -1 &&
                    updatedRoom.subjects[subjectIndex].subjectSpecificTime[sstIndex].status === 'reserved') {
                    updatedRoom.subjects[subjectIndex].subjectSpecificTime[sstIndex].status = 'joined';
                    updatedRoom.subjects[subjectIndex].subjectSpecificTime[sstIndex].joinedBy = user.email;
                    await handleRoomStatusUpdate(updatedRoom);
                }
            }
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error joining room:', error);
            }
            alert('Failed to join room. Please try again.');
        }
    };
    const handleStartRoom = async (room, subjectName, slotTime) => {
        try {
            const updatedRoom = { ...statuses[room] };
            const subjectIndex = updatedRoom.subjects.findIndex(s => s.name === subjectName);
            if (subjectIndex !== -1) {
                const sstIndex = updatedRoom.subjects[subjectIndex].subjectSpecificTime.findIndex(sst => {
                    const sstSlotTime = new Date(sst.slotTime);
                    return sstSlotTime.getTime() === slotTime.getTime();
                });
                if (sstIndex !== -1 &&
                    updatedRoom.subjects[subjectIndex].subjectSpecificTime[sstIndex].status === 'joined') {
                    updatedRoom.subjects[subjectIndex].subjectSpecificTime[sstIndex].status = 'started';
                    await handleRoomStatusUpdate(updatedRoom);
                }
            }
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error starting room:', error);
            }
            alert('Failed to start room. Please try again.');
        }
    };
    // Additional Handlers and Utilities
    const handleBackToReserved = async (room, subjectName, slotTime) => {
        if (!user || !statuses[room])
            return;
        try {
            const updatedRoom = { ...statuses[room] };
            const subjectIndex = updatedRoom.subjects.findIndex(s => s.name === subjectName);
            if (subjectIndex !== -1) {
                const sstIndex = updatedRoom.subjects[subjectIndex].subjectSpecificTime.findIndex(sst => {
                    const sstSlotTime = new Date(sst.slotTime);
                    return sstSlotTime.getTime() === slotTime.getTime();
                });
                const sst = updatedRoom.subjects[subjectIndex].subjectSpecificTime[sstIndex];
                if (sstIndex !== -1 &&
                    (sst.status === 'joined' || sst.status === 'started') &&
                    sst.joinedBy === user.email) {
                    sst.status = 'reserved';
                    sst.joinedBy = '';
                    await handleRoomStatusUpdate(updatedRoom);
                }
            }
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error returning room to reserved:', error);
            }
            alert('Failed to return room to reserved state. Please try again.');
        }
    };
    const handleRoomFinished = async (room, subjectName, slotTime) => {
        if (!user || !statuses[room])
            return;
        try {
            const updatedRoom = { ...statuses[room] };
            const subjectIndex = updatedRoom.subjects.findIndex(s => s.name === subjectName);
            if (subjectIndex !== -1) {
                const sstIndex = updatedRoom.subjects[subjectIndex].subjectSpecificTime.findIndex(sst => {
                    const sstSlotTime = new Date(sst.slotTime);
                    return sstSlotTime.getTime() === slotTime.getTime();
                });
                if (sstIndex !== -1 &&
                    updatedRoom.subjects[subjectIndex].subjectSpecificTime[sstIndex].status === 'started') {
                    const sst = updatedRoom.subjects[subjectIndex].subjectSpecificTime[sstIndex];
                    sst.status = 'finished';
                    sst.joinedBy = '';
                    sst.notification = '';
                    sst.notificationTime = null;
                    await handleRoomStatusUpdate(updatedRoom);
                }
            }
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error finishing room:', error);
            }
            alert('Failed to finish room. Please try again.');
        }
    };
    const toggleRoomsVisibility = () => setShowRooms(!showRooms);
    const getUserNameByEmail = (email) => {
        const user = allUsersFront.find(user => user.email === email);
        return user ? `${user.firstName} ${user.lastName}` : 'Unknown';
    };
    const formatNotificationTime = (notificationTime) => {
        if (!notificationTime)
            return { time: '', icon: _jsx(_Fragment, {}) };
        const notificationDate = new Date(notificationTime);
        const helsinkiNotificationDate = new Date(notificationDate.toLocaleString("en-US", { timeZone: cityConfig.timezone }));
        const diffMilliseconds = currentTime.getTime() - helsinkiNotificationDate.getTime();
        const diffMinutes = Math.max(0, Math.floor(diffMilliseconds / 60000));
        let icon;
        if (diffMinutes < 15) {
            icon = _jsx(PiSmileyFill, { title: `${diffMinutes} min` });
        }
        else if (diffMinutes < 30) {
            icon = _jsx(PiSmileyMeltingFill, { title: `${diffMinutes} min` });
        }
        else {
            icon = _jsx(PiSmileyMehFill, { title: `${diffMinutes} min` });
        }
        return { time: `${diffMinutes} min`, icon };
    };
    // Chat Handlers
    const toggleReasonHeader = (room, subjectName, slotTime) => {
        setShowReasonHeader(prev => ({
            ...prev,
            [room]: {
                ...(prev[room] || {}),
                [subjectName]: {
                    ...(prev[room]?.[subjectName] || {}),
                    [slotTime.toString()]: !(prev[room]?.[subjectName]?.[slotTime.toString()] || false)
                }
            }
        }));
    };
    const toggleChat = (room, subjectName, slotTime) => {
        const newChatVisibility = !isChatVisible;
        setSelectedRoom(room);
        setSelectedSubject(subjectName);
        setSelectedSlotTime(slotTime);
        setIsChatVisible(newChatVisibility);
        socket.emit('toggleChat', {
            room,
            subjectName,
            slotTime: slotTime.toISOString(),
            chatStatus: newChatVisibility ? 'on' : 'off',
            userRole: 'teacher'
        });
    };
    const handleUpdateChatStatus = async (status) => {
        if (selectedRoom && selectedSubject && selectedSlotTime) {
            try {
                const updatedRoom = { ...statuses[selectedRoom] };
                const subject = updatedRoom.subjects.find(s => s.name === selectedSubject);
                const sst = subject?.subjectSpecificTime.find(s => new Date(s.slotTime).getTime() === selectedSlotTime.getTime());
                if (sst) {
                    sst.chatTeacher = status;
                    await handleRoomStatusUpdate(updatedRoom);
                }
            }
            catch (error) {
                if (CONFIG.DEBUG) {
                    console.error('Error updating chat status:', error);
                }
            }
        }
    };
    // Cleanup effect for chat
    useEffect(() => {
        const cleanup = () => {
            if (selectedRoom && selectedSubject && selectedSlotTime) {
                const updatedRoom = { ...statuses[selectedRoom] };
                const subject = updatedRoom.subjects.find(s => s.name === selectedSubject);
                const sst = subject?.subjectSpecificTime.find(s => new Date(s.slotTime).getTime() === selectedSlotTime.getTime());
                if (sst) {
                    sst.chatStudent = 'off';
                    handleRoomStatusUpdate(updatedRoom)
                        .catch(error => {
                        if (CONFIG.DEBUG) {
                            console.error('Error during cleanup:', error);
                        }
                    });
                }
            }
        };
        window.addEventListener('beforeunload', cleanup);
        return () => {
            window.removeEventListener('beforeunload', cleanup);
            cleanup();
        };
    }, [selectedRoom, selectedSubject, selectedSlotTime, statuses]);
    return (_jsxs(_Fragment, { children: [_jsx(HeaderTeacher, { onOpenWhiteboard: () => setIsWhiteboardOpen(true) }), _jsx(Whiteboard, { isOpen: isWhiteboardOpen, onClose: () => setIsWhiteboardOpen(false), userType: "teacher" }), _jsx("div", { className: "mainUI", children: showRooms ? (_jsx(Ohjauslista, { statuses: statuses, handleJoinRoom: handleJoinRoom, handleBackToReserved: handleBackToReserved, getUserNameByEmail: getUserNameByEmail, toggleReasonHeader: toggleReasonHeader, showReasonHeader: showReasonHeader, toggleRoomsVisibility: toggleRoomsVisibility, isChatVisible: isChatVisible, subjects: cityConfig.subjects, userEmail: user?.email || '', updateRoomStatus: handleRoomStatusUpdate })) : isChatVisible && selectedRoom && selectedSubject && selectedSlotTime ? (_jsx("div", { className: 'teacherUI', children: _jsx(Chat, { room: statuses[selectedRoom], user: user, selectedSubject: selectedSubject, selectedSlotTime: selectedSlotTime, displayReceiverAs: "teacher", allUsers: allUsersFront, onUpdateRoom: handleRoomStatusUpdate, onToggleChatVisibility: setIsChatVisible, onUpdateChatStatus: handleUpdateChatStatus, city: userCity }) })) : (_jsx(Ohjattavat, { statuses: statuses, handleBackToReserved: handleBackToReserved, handleRoomFinished: handleRoomFinished, handleStartRoom: handleStartRoom, getUserNameByEmail: getUserNameByEmail, toggleChat: toggleChat, toggleRoomsVisibility: toggleRoomsVisibility, isChatVisible: isChatVisible, formatNotificationTime: formatNotificationTime, userEmail: user?.email || '', updateRoomStatus: handleRoomStatusUpdate })) })] }));
};
export default TeacherUI;
