import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MyKlinikio from './components/global/myklinikio';
import Testing from './components/global/testing';
import LoginForm from './components/global/LoginForm';
import AddUser from './components/global/AddUserForm';
import TeacherUI from './components/Helsinki/HelsinkiUI/teacherUI';
import StudentUI from './components/Helsinki/HelsinkiUI/studentUI';
import ReleaseUI from './components/Helsinki/HelsinkiUI/releaseUI';
import RoomViewUI from './components/Helsinki/HelsinkiUI/roomViewUI';
import NotificationUI from './components/Helsinki/HelsinkiUI/notificationUI';
import PrivateRoute from './components/global/PrivateRoute';
import { AuthProvider } from './components/global/LoginAuth';
function App() {
    return (_jsx(Router, { children: _jsx(AuthProvider, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(MyKlinikio, {}) }), _jsx(Route, { path: "/testing", element: _jsx(Testing, {}) }), _jsx(Route, { path: "/login", element: _jsx(LoginForm, {}) }), _jsx(Route, { path: "/superadmin", element: _jsx(AddUser, {}) }), _jsx(Route, { path: "helsinki/releases", element: _jsx(ReleaseUI, {}) }), _jsx(Route, { path: "helsinki/:username/kartta", element: _jsx(PrivateRoute, { role: "student", children: _jsx(StudentUI, {}) }) }), _jsx(Route, { path: "helsinki/:username/ohjaus", element: _jsx(PrivateRoute, { role: "student", children: _jsx(RoomViewUI, {}) }) }), _jsx(Route, { path: "helsinki/:username/opetus", element: _jsx(PrivateRoute, { role: "teacher", children: _jsx(TeacherUI, {}) }) }), _jsx(Route, { path: "helsinki/:username/ilmoitukset", element: _jsx(PrivateRoute, { role: "teacher", children: _jsx(NotificationUI, {}) }) }), _jsx(Route, { path: "turku/releases", element: _jsx(ReleaseUI, {}) }), _jsx(Route, { path: "turku/:username/kartta", element: _jsx(PrivateRoute, { role: "student", children: _jsx(StudentUI, {}) }) }), _jsx(Route, { path: "turku/:username/ohjaus", element: _jsx(PrivateRoute, { role: "student", children: _jsx(RoomViewUI, {}) }) }), _jsx(Route, { path: "turku/:username/opetus", element: _jsx(PrivateRoute, { role: "teacher", children: _jsx(TeacherUI, {}) }) }), _jsx(Route, { path: "turku/:username/ilmoitukset", element: _jsx(PrivateRoute, { role: "teacher", children: _jsx(NotificationUI, {}) }) })] }) }) }));
}
export default App;
