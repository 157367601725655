import { useEffect, useState } from 'react';
export const useAllUsersDatabase = (city) => {
    const [allUsers, setAllUsers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                console.log('Fetching users for city:', city);
                const response = await fetch(`${process.env.REACT_APP_API_URL || ''}/database/users_${city}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include'
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log('Users data received:', data);
                if (data.success && Array.isArray(data.users)) {
                    setAllUsers(data.users);
                }
                else {
                    console.error('Invalid users data format:', data);
                    throw new Error(data.error || 'Failed to fetch users: Invalid data format');
                }
            }
            catch (error) {
                console.error('Error fetching users:', error);
                setError(error);
            }
            finally {
                setIsLoading(false);
            }
        };
        fetchUsers();
    }, [city]);
    return { users: allUsers, error, isLoading };
};
export const useUserDetailsDatabase = (userEmail, city) => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetchUserDetails = async () => {
            if (!userEmail) {
                setIsLoading(false);
                return;
            }
            try {
                console.log('Fetching user details for:', userEmail);
                const response = await fetch(`${process.env.REACT_APP_API_URL || ''}/database/users_${city}/findUser`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ email: userEmail })
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log('User details received:', data);
                if (data.success && data.user) {
                    setUser(data.user);
                }
                else {
                    console.error('Invalid user data format:', data);
                    throw new Error(data.error || 'Failed to fetch user details: Invalid data format');
                }
            }
            catch (error) {
                console.error('Error fetching user details:', error);
                setError(error);
            }
            finally {
                setIsLoading(false);
            }
        };
        fetchUserDetails();
    }, [userEmail, city]);
    return { user, error, isLoading };
};
// Add a simple function to check if a user exists
export const checkUserExists = async (email, city) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL || ''}/database/users_${city}/findUser`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ email })
        });
        if (!response.ok) {
            return false;
        }
        const data = await response.json();
        return data.success && data.user !== null;
    }
    catch (error) {
        console.error('Error checking user existence:', error);
        return false;
    }
};
// Add a function to handle user data caching
const userCache = new Map();
export const getCachedUser = async (email, city) => {
    const cacheKey = `${city}:${email}`;
    if (userCache.has(cacheKey)) {
        return userCache.get(cacheKey) || null;
    }
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL || ''}/database/users_${city}/findUser`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ email })
        });
        if (!response.ok) {
            return null;
        }
        const data = await response.json();
        if (data.success && data.user) {
            userCache.set(cacheKey, data.user);
            return data.user;
        }
        return null;
    }
    catch (error) {
        console.error('Error fetching cached user:', error);
        return null;
    }
};
export const clearUserCache = () => {
    userCache.clear();
};
