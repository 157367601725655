import { CITIES } from './cities';
export const DEFAULT_CITY = 'helsinki';
const getEnvironmentConfig = () => {
    const env = process.env.REACT_APP_ENV || 'development';
    const isProduction = env === 'production';
    const defaultCity = DEFAULT_CITY;
    return {
        API_URL: isProduction
            ? 'https://www.myklinik.io'
            : process.env.REACT_APP_API_URL || 'http://127.0.0.1:4010',
        SOCKET_URL: isProduction
            ? 'https://www.myklinik.io'
            : process.env.REACT_APP_SOCKET_URL || 'http://127.0.0.1:4010',
        ENV: isProduction ? 'production' : 'development',
        SOCKET_IO: process.env.REACT_APP_ENABLE_SOCKET_IO === 'true',
        CHAT: process.env.REACT_APP_ENABLE_CHAT === 'true',
        DEBUG: process.env.REACT_APP_DEBUG === 'true',
        ENDPOINTS: CITIES[defaultCity].apiEndpoints
    };
};
export const CONFIG = getEnvironmentConfig();
export const getCityConfig = (cityName) => {
    const lowerCityName = cityName.toLowerCase();
    const cityConfig = CITIES[lowerCityName];
    if (!cityConfig) {
        throw new Error(`Configuration for city ${cityName} not found`);
    }
    return cityConfig;
};
export const getEndpoints = (cityName = DEFAULT_CITY) => {
    return getCityConfig(cityName).apiEndpoints;
};
export const getRoomLayout = (cityName = DEFAULT_CITY) => {
    return getCityConfig(cityName).roomLayout;
};
export const getCurrentCity = (userCity) => {
    if (userCity && isValidCity(userCity.toLowerCase())) {
        return userCity.toLowerCase();
    }
    return DEFAULT_CITY;
};
export const isValidCity = (city) => {
    return Boolean(city?.toLowerCase() in CITIES);
};
export { CITIES };
