import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import io from 'socket.io-client';
import '../../../styling/universal/global.css';
import '../../../styling/universal/mainUI.css';
import '../../../styling/universal/studentUI.css';
import HeaderStudent from './HeaderStudent';
import Whiteboard from '../../global/whiteboard';
import { useAllUsersDatabase } from '../../global/FetchUsers';
import { CONFIG, getCityConfig, getEndpoints } from '../../../config/config';
const apiRequest = async (endpoint, options = {}) => {
    try {
        const response = await fetch(endpoint, {
            ...options,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                ...options.headers,
            },
            credentials: 'include'
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (CONFIG.DEBUG) {
            console.log(`API Response from ${endpoint}:`, data);
        }
        return data;
    }
    catch (error) {
        if (CONFIG.DEBUG) {
            console.error(`API Error for ${endpoint}:`, error);
        }
        throw error;
    }
};
const StudentUI = () => {
    const navigate = useNavigate();
    const { city } = useParams();
    const userCity = city;
    const cityConfig = useMemo(() => getCityConfig(userCity), [userCity]);
    const cityEndpoints = useMemo(() => getEndpoints(userCity), [userCity]);
    const socket = useMemo(() => io(CONFIG.SOCKET_URL, {
        transports: ['websocket'],
        reconnectionAttempts: parseInt(process.env.REACT_APP_SOCKET_RECONNECT_ATTEMPTS || '5'),
        reconnectionDelay: parseInt(process.env.REACT_APP_SOCKET_RECONNECT_DELAY || '1000'),
        autoConnect: true,
        auth: { token: localStorage.getItem('token') }
    }), []);
    const [roomLayout, setRoomLayout] = useState({});
    const [roomStatuses, setRoomStatuses] = useState({});
    const [user, setUser] = useState(null);
    const [isWhiteboardOpen, setIsWhiteboardOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { users: allUsers } = useAllUsersDatabase(userCity);
    const allUsersFront = useMemo(() => {
        return (allUsers || []).map(user => ({
            _id: user._id || '',
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password || '',
            city: user.city || '',
            role: user.role
        }));
    }, [allUsers]);
    const getFullNameByEmail = (email) => {
        const user = allUsersFront.find(user => user.email === email);
        return user ? `${user.firstName} ${user.lastName}` : 'Unknown';
    };
    // Initial data loading
    useEffect(() => {
        const loadInitialData = async () => {
            const userID = localStorage.getItem('userID');
            if (!userID) {
                navigate('/login');
                return;
            }
            setIsLoading(true);
            try {
                const [userData, roomLayoutData, roomStatusData] = await Promise.all([
                    apiRequest(`${cityEndpoints.USER}/${userID}`),
                    apiRequest(cityEndpoints.ROOM_LAYOUT),
                    apiRequest(cityEndpoints.ROOMS)
                ]);
                if (userData.success) {
                    setUser(userData.user);
                    const initialRoomStatuses = {};
                    roomStatusData.forEach((roomStatus) => {
                        initialRoomStatuses[roomStatus.room] = roomStatus;
                    });
                    setRoomStatuses(initialRoomStatuses);
                    checkForExistingReservation(initialRoomStatuses, userData.user);
                    setRoomLayout(roomLayoutData);
                }
                else {
                    throw new Error('Failed to fetch user data');
                }
            }
            catch (error) {
                if (CONFIG.DEBUG) {
                    console.error('Error loading initial data:', error);
                }
                setError('Failed to load initial data. Please try again.');
            }
            finally {
                setIsLoading(false);
            }
        };
        loadInitialData();
    }, [navigate, cityEndpoints]);
    // Socket connection handling
    useEffect(() => {
        const handleRoomStatusUpdate = (updatedStatuses) => {
            setRoomStatuses(prevStatuses => {
                const newStatuses = { ...prevStatuses, ...updatedStatuses };
                if (user) {
                    checkForExistingReservation(newStatuses, user);
                }
                return newStatuses;
            });
        };
        const handleSocketError = (error) => {
            if (CONFIG.DEBUG) {
                console.error('Socket error:', error);
            }
            setError('Connection error. Please refresh the page.');
        };
        socket.on(`roomStatusUpdate${userCity?.charAt(0).toUpperCase()}${userCity?.slice(1)}`, handleRoomStatusUpdate);
        socket.on('error', handleSocketError);
        socket.on('connect_error', handleSocketError);
        return () => {
            socket.off(`roomStatusUpdate${userCity?.charAt(0).toUpperCase()}${userCity?.slice(1)}`, handleRoomStatusUpdate);
            socket.off('error', handleSocketError);
            socket.off('connect_error', handleSocketError);
        };
    }, [user, userCity, socket]);
    const checkForExistingReservation = (roomStatuses, user) => {
        const reservedRoomData = Object.values(roomStatuses).find(room => room.roomReservedBy === user.email);
        if (reservedRoomData) {
            const username = user.email.split('@')[0].replace('.', '');
            navigate(`/${userCity}/${username}/ohjaus`);
        }
    };
    const reserveRoom = async (room) => {
        if (!user) {
            alert('Please ensure you are logged in.');
            return;
        }
        const roomStatus = roomStatuses[room];
        if (roomStatus.status !== 'neutral') {
            alert('This room is not available.');
            return;
        }
        try {
            const updatedRoomStatus = {
                ...roomStatus,
                status: 'reserved',
                roomReservedBy: user.email,
            };
            await updateRoomStatus(room, updatedRoomStatus);
            const username = user.email.split('@')[0].replace('.', '');
            navigate(`/${userCity}/${username}/ohjaus`);
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error reserving room:', error);
            }
            alert('Failed to reserve the room. Please try again.');
        }
    };
    const updateRoomStatus = async (room, updatedRoomStatus) => {
        try {
            await apiRequest(cityEndpoints.ROOMS_UPDATE, {
                method: 'POST',
                body: JSON.stringify(updatedRoomStatus),
            });
            setRoomStatuses(prev => ({ ...prev, [room]: updatedRoomStatus }));
            socket.emit(`updateRoomStatus${userCity?.charAt(0).toUpperCase()}${userCity?.slice(1)}`, { [room]: updatedRoomStatus });
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error updating room status:', error);
            }
            throw error;
        }
    };
    if (isLoading) {
        return _jsx("div", { className: "loading", children: "Loading..." });
    }
    if (error) {
        return _jsx("div", { className: "error", children: error });
    }
    return (_jsxs("div", { children: [_jsx(HeaderStudent, { onOpenWhiteboard: () => setIsWhiteboardOpen(true) }), _jsx(Whiteboard, { isOpen: isWhiteboardOpen, onClose: () => setIsWhiteboardOpen(false), userType: "student" }), _jsx("div", { className: "mainUI", children: _jsxs("div", { className: "studentUI", children: [_jsxs("div", { className: "cardbox", children: [_jsx("div", { className: "titlebox", children: _jsx("span", { className: 'title_text', children: "varaus" }) }), _jsx("div", { className: "function_group", children: Object.entries(roomLayout).map(([groupLabel, rooms]) => (_jsx("div", { className: "room_group", children: _jsx("div", { className: "groups", children: rooms.map((room) => {
                                                const currentRoomStatus = roomStatuses[room];
                                                const reservedByName = currentRoomStatus?.roomReservedBy ?
                                                    getFullNameByEmail(currentRoomStatus.roomReservedBy) : '';
                                                return (_jsx("div", { className: `room ${currentRoomStatus?.status}`, onClick: () => reserveRoom(room), title: currentRoomStatus?.status === 'reserved' ?
                                                        `Varattu: ${reservedByName}` :
                                                        currentRoomStatus?.status === 'neutral' ?
                                                            'Varaa' : '', children: _jsx("div", { className: `room_label ${currentRoomStatus?.status}`, children: room }) }, room));
                                            }) }) }, groupLabel))) })] }), _jsx("span", { className: "buttonbox_trans" })] }) })] }));
};
export default StudentUI;
