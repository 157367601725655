import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import io from 'socket.io-client';
import Reason from './reason';
import RoomUI from './roomUI';
import HeaderStudent from './HeaderStudent';
import Whiteboard from '../../global/whiteboard';
import Chat from '../../global/chat';
import { useAllUsersDatabase } from '../../global/FetchUsers';
import { CONFIG, getCityConfig, getEndpoints } from '../../../config/config';
import '../../../styling/universal/mainUI.css';
import '../../../styling/universal/studentUI.css';
import '../../../styling/universal/global.css';
import { IoArrowBackCircle } from "react-icons/io5";
// API helper function
const apiRequest = async (endpoint, options = {}) => {
    try {
        const response = await fetch(endpoint, {
            ...options,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                ...options.headers,
            },
            credentials: 'include'
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (CONFIG.DEBUG) {
            console.log(`API Response from ${endpoint}:`, data);
        }
        return data;
    }
    catch (error) {
        if (CONFIG.DEBUG) {
            console.error(`API Error for ${endpoint}:`, error);
        }
        throw error;
    }
};
const RoomViewUI = () => {
    const navigate = useNavigate();
    const { city } = useParams();
    const userCity = city;
    const cityConfig = useMemo(() => getCityConfig(userCity), [userCity]);
    const cityEndpoints = useMemo(() => getEndpoints(userCity), [userCity]);
    const socket = useMemo(() => io(CONFIG.SOCKET_URL, {
        transports: ['websocket'],
        reconnectionAttempts: parseInt(process.env.REACT_APP_SOCKET_RECONNECT_ATTEMPTS || '5'),
        reconnectionDelay: parseInt(process.env.REACT_APP_SOCKET_RECONNECT_DELAY || '1000'),
        autoConnect: true,
        auth: { token: localStorage.getItem('token') }
    }), []);
    // State management
    const [state, setState] = useState({
        user: null,
        room: null,
        showReason: true,
        unreadMessages: {},
        isWhiteboardOpen: false,
        isLoading: true,
        isChatVisible: false,
        selectedSubject: null,
        selectedSlotTime: null,
        existingSubjectReasons: [],
        error: null
    });
    const { users: allUsers } = useAllUsersDatabase(userCity);
    const allUsersFront = useMemo(() => {
        return (allUsers || []).map(user => ({
            _id: user._id || '',
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password || '',
            city: user.city || '',
            role: user.role
        }));
    }, [allUsers]);
    const updateState = (updates) => {
        setState(prev => ({ ...prev, ...updates }));
    };
    // Room data management
    const fetchRoomData = async (email) => {
        if (CONFIG.DEBUG) {
            console.log('Fetching room data for email:', email);
        }
        try {
            const rooms = await apiRequest(cityEndpoints.ROOMS);
            const userRoom = rooms.find(room => room.roomReservedBy === email);
            if (CONFIG.DEBUG) {
                console.log('User room:', userRoom);
            }
            if (userRoom && userRoom.subjects) {
                const activeSubjects = userRoom.subjects.filter(subject => subject.subjectSpecificTime.some(sst => sst.status !== 'neutral'));
                updateState({
                    room: userRoom,
                    showReason: activeSubjects.length === 0,
                    isLoading: false
                });
            }
            else {
                updateState({
                    room: null,
                    isLoading: false
                });
            }
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error fetching room data:', error);
            }
            updateState({
                error: 'Failed to load room data. Please try again.',
                isLoading: false
            });
        }
    };
    const updateRoomStatus = async (updatedRoom) => {
        try {
            await apiRequest(cityEndpoints.ROOMS_UPDATE, {
                method: 'POST',
                body: JSON.stringify(updatedRoom)
            });
            socket.emit(`updateRoomStatus${userCity.charAt(0).toUpperCase()}${userCity.slice(1)}`, { [updatedRoom.room]: updatedRoom });
            updateState({ room: updatedRoom });
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error updating room status:', error);
            }
            throw new Error('Failed to update room status');
        }
    };
    // Effect Hooks
    useEffect(() => {
        const initializeData = async () => {
            const userID = localStorage.getItem('userID');
            if (!userID) {
                navigate('/login');
                return;
            }
            try {
                const userData = await apiRequest(`${cityEndpoints.USER}/${userID}`);
                if (userData.success) {
                    updateState({ user: userData.user });
                    await fetchRoomData(userData.user.email);
                }
                else {
                    throw new Error('Failed to fetch user data');
                }
            }
            catch (error) {
                if (CONFIG.DEBUG) {
                    console.error('Error during initialization:', error);
                }
                updateState({
                    error: 'Failed to initialize. Please try again.',
                    isLoading: false
                });
            }
        };
        initializeData();
    }, [navigate, cityEndpoints]);
    // Socket event handlers
    useEffect(() => {
        const handleRoomStatusUpdate = (updatedStatuses) => {
            const { user } = state;
            if (!user)
                return;
            const userRoom = Object.values(updatedStatuses).find(room => room.roomReservedBy === user.email);
            if (userRoom) {
                updateState({ room: userRoom });
                if (userRoom.subjects.some((subject) => subject.subjectSpecificTime.some(sst => sst.status !== 'neutral'))) {
                    updateState({ showReason: false });
                }
                // Update unread messages
                const newUnreadMessages = processUnreadMessages(userRoom, state.room);
                if (Object.keys(newUnreadMessages).length > 0) {
                    updateState({
                        unreadMessages: {
                            ...state.unreadMessages,
                            ...newUnreadMessages
                        }
                    });
                }
            }
            else {
                updateState({ room: null });
            }
        };
        const handleSocketError = (error) => {
            if (CONFIG.DEBUG) {
                console.error('Socket error:', error);
            }
            updateState({
                error: 'Connection error. Please refresh the page.'
            });
        };
        socket.on(`roomStatusUpdate${userCity.charAt(0).toUpperCase()}${userCity.slice(1)}`, handleRoomStatusUpdate);
        socket.on('error', handleSocketError);
        socket.on('connect_error', handleSocketError);
        return () => {
            socket.off(`roomStatusUpdate${userCity.charAt(0).toUpperCase()}${userCity.slice(1)}`, handleRoomStatusUpdate);
            socket.off('error', handleSocketError);
            socket.off('connect_error', handleSocketError);
        };
    }, [state.user, state.room, userCity, socket]);
    // Chat-related socket events and cleanup
    useEffect(() => {
        const handleChatUpdate = (updatedRoom) => {
            updateState({ room: updatedRoom });
        };
        const handleMessageReceived = (updatedRoom) => {
            handleChatUpdate(updatedRoom);
            const newUnreadMessages = processUnreadMessages(updatedRoom);
            updateState({ unreadMessages: newUnreadMessages });
        };
        socket.on('chatUpdate', handleChatUpdate);
        socket.on('messageReceived', handleMessageReceived);
        return () => {
            socket.off('chatUpdate', handleChatUpdate);
            socket.off('messageReceived', handleMessageReceived);
        };
    }, [socket]);
    // Cleanup effect
    useEffect(() => {
        const cleanup = async () => {
            const { room, selectedSubject, selectedSlotTime } = state;
            if (room && selectedSubject && selectedSlotTime) {
                const updatedRoom = { ...room };
                const subject = updatedRoom.subjects.find(s => s.name === selectedSubject);
                const sst = subject?.subjectSpecificTime.find(s => new Date(s.slotTime).getTime() === selectedSlotTime.getTime());
                if (sst) {
                    sst.chatStudent = 'off';
                    try {
                        await updateRoomStatus(updatedRoom);
                    }
                    catch (error) {
                        if (CONFIG.DEBUG) {
                            console.error('Error during cleanup:', error);
                        }
                    }
                }
            }
        };
        window.addEventListener('beforeunload', cleanup);
        return () => {
            window.removeEventListener('beforeunload', cleanup);
            cleanup();
        };
    }, [state.room, state.selectedSubject, state.selectedSlotTime]);
    const handleGoToStudentUI = async () => {
        const { user, room } = state;
        if (user?.email) {
            const username = user.email.split('@')[0].replace(/\./g, '');
            if (room) {
                try {
                    await handleRemoveReservation();
                }
                catch (error) {
                    if (CONFIG.DEBUG) {
                        console.error('Error removing reservation:', error);
                    }
                }
            }
            navigate(`/${userCity}/${username}/kartta`);
        }
        else {
            if (CONFIG.DEBUG) {
                console.error('User or user email is not available');
            }
            navigate('/login');
        }
    };
    const handleRemoveReservation = async () => {
        const { room, user } = state;
        if (!room || !user) {
            updateState({ error: 'No room reserved or user not logged in.' });
            return;
        }
        try {
            const resetSubjects = room.subjects.map(subject => ({
                ...subject,
                subjectSpecificTime: subject.subjectSpecificTime.map(sst => ({
                    ...sst,
                    status: 'neutral',
                    reason: '',
                    reservedBy: '',
                    joinedBy: '',
                    notification: '',
                    notificationTime: null,
                    messageStudent: [],
                    messageTeacher: []
                }))
            }));
            const resetRoomStatus = {
                ...room,
                status: 'neutral',
                roomReservedBy: '',
                subjects: resetSubjects
            };
            await updateRoomStatus(resetRoomStatus);
            navigate(`/${userCity}/:username/kartta`);
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error removing reservation:', error);
            }
            updateState({ error: 'Failed to remove reservation. Please try again.' });
        }
    };
    const handleReasonSubmit = async (subjectReasons) => {
        const { room, user } = state;
        if (!room || !user) {
            updateState({ error: 'Room data or user not found.' });
            return;
        }
        try {
            const updatedSubjects = room.subjects.map(subject => {
                const selectedSubjectReasons = subjectReasons.filter(sr => sr.name === subject.name && sr.slotTime !== null);
                if (selectedSubjectReasons.length > 0) {
                    return {
                        ...subject,
                        subjectSpecificTime: selectedSubjectReasons.map(sr => ({
                            slotTime: sr.slotTime,
                            status: 'reserved',
                            reason: sr.reason,
                            reservedBy: user.email,
                            joinedBy: '',
                            notification: '',
                            notificationTime: null,
                            chatStudent: 'off',
                            chatTeacher: 'off',
                            messageStudent: [],
                            messageTeacher: []
                        }))
                    };
                }
                return subject;
            });
            const updatedRoomStatus = {
                ...room,
                status: 'reserved',
                roomReservedBy: user.email,
                subjects: updatedSubjects
            };
            await updateRoomStatus(updatedRoomStatus);
            updateState({ showReason: false });
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error updating room subjects:', error);
            }
            updateState({ error: 'Failed to update room subjects. Please try again.' });
        }
    };
    const handleNotificationButtonClick = async (subjectName, notificationType, slotTime) => {
        const { room } = state;
        if (!room)
            return;
        try {
            const updatedSubjects = room.subjects.map(subject => {
                if (subject.name === subjectName) {
                    return {
                        ...subject,
                        subjectSpecificTime: subject.subjectSpecificTime.map(sst => {
                            const sstSlotTime = new Date(sst.slotTime);
                            if (sstSlotTime.getTime() === slotTime.getTime()) {
                                const currentNotification = sst.notification;
                                const newNotification = currentNotification === notificationType ? '' : notificationType;
                                return {
                                    ...sst,
                                    notification: newNotification,
                                    notificationTime: newNotification ? new Date() : null,
                                };
                            }
                            return sst;
                        })
                    };
                }
                return subject;
            });
            const updatedRoom = { ...room, subjects: updatedSubjects };
            await updateRoomStatus(updatedRoom);
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error updating notification:', error);
            }
            updateState({ error: 'Failed to update notification. Please try again.' });
        }
    };
    const handleChatToggle = (subjectName, slotTime) => {
        const { room, isChatVisible } = state;
        const newChatVisibility = !isChatVisible;
        if (room) {
            socket.emit('toggleChat', {
                room: room.room,
                subjectName,
                slotTime: slotTime.toISOString(),
                chatStatus: newChatVisibility ? 'on' : 'off',
                userRole: 'student',
                city: userCity
            });
            if (newChatVisibility) {
                updateState({
                    unreadMessages: {
                        ...state.unreadMessages,
                        [subjectName]: {
                            ...(state.unreadMessages[subjectName] || {}),
                            [slotTime.toISOString()]: false
                        }
                    }
                });
            }
        }
        updateState({
            isChatVisible: newChatVisibility,
            selectedSubject: subjectName,
            selectedSlotTime: slotTime
        });
    };
    const handleChatStatusUpdate = async (status) => {
        const { room, selectedSubject, selectedSlotTime } = state;
        if (!room || !selectedSubject || !selectedSlotTime)
            return;
        try {
            const updatedRoom = { ...room };
            const subject = updatedRoom.subjects.find(s => s.name === selectedSubject);
            const sst = subject?.subjectSpecificTime.find(s => new Date(s.slotTime).getTime() === selectedSlotTime.getTime());
            if (sst) {
                sst.chatStudent = status;
                await updateRoomStatus(updatedRoom);
            }
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error updating chat status:', error);
            }
            updateState({ error: 'Failed to update chat status.' });
        }
    };
    const handleGoBackToReason = () => {
        const { room } = state;
        if (room) {
            const subjectReasons = room.subjects.flatMap(subject => subject.subjectSpecificTime.map(sst => ({
                name: subject.name,
                slotTime: new Date(sst.slotTime),
                reason: sst.reason
            }))).filter(sr => sr.reason !== '');
            updateState({
                existingSubjectReasons: subjectReasons,
                showReason: true
            });
        }
    };
    const getUserNameByEmail = (email) => {
        const user = allUsersFront.find(user => user.email === email);
        return user ? `${user.firstName} ${user.lastName}` : 'Unknown';
    };
    // Helper function to process unread messages
    const processUnreadMessages = (newRoom, prevRoom = null) => {
        const newUnreadMessages = {};
        newRoom.subjects.forEach(subject => {
            subject.subjectSpecificTime.forEach(sst => {
                const prevSubject = prevRoom?.subjects.find(s => s.name === subject.name);
                const prevSst = prevSubject?.subjectSpecificTime.find(s => new Date(s.slotTime).getTime() === new Date(sst.slotTime).getTime());
                if (sst.messageTeacher.length > (prevSst?.messageTeacher.length || 0)) {
                    if (!newUnreadMessages[subject.name]) {
                        newUnreadMessages[subject.name] = {};
                    }
                    newUnreadMessages[subject.name][new Date(sst.slotTime).toISOString()] = true;
                }
            });
        });
        return newUnreadMessages;
    };
    // Render loading state
    if (state.isLoading) {
        return _jsx("div", { className: "loading", children: "Loading..." });
    }
    // Render error state
    if (state.error) {
        return _jsx("div", { className: "error", children: state.error });
    }
    // Render empty state
    if (!state.room) {
        return (_jsxs("div", { className: 'mainUI', children: [_jsxs("div", { className: 'studentUI', children: [_jsx(HeaderStudent, { onOpenWhiteboard: () => updateState({ isWhiteboardOpen: true }) }), _jsx(Whiteboard, { isOpen: state.isWhiteboardOpen, onClose: () => updateState({ isWhiteboardOpen: false }), userType: state.user?.role === 'teacher' ? 'teacher' : 'student' }), _jsx("div", { className: 'infobox', children: "varaa boksi ja ohjaukset painamalla \"edellinen\"" })] }), _jsx("div", { className: 'bottombox', children: _jsx("button", { title: 'edellinen', className: "menubutton_light", onClick: handleGoToStudentUI, children: _jsx(IoArrowBackCircle, {}) }) })] }));
    }
    // Main render
    return (_jsxs(_Fragment, { children: [_jsx(HeaderStudent, { onOpenWhiteboard: () => updateState({ isWhiteboardOpen: true }) }), _jsx(Whiteboard, { isOpen: state.isWhiteboardOpen, onClose: () => updateState({ isWhiteboardOpen: false }), userType: state.user?.role === 'teacher' ? 'teacher' : 'student' }), _jsx("div", { className: 'mainUI', children: !state.isChatVisible ? (_jsx("div", { children: state.showReason ? (_jsx(Reason, { onReasonSubmit: handleReasonSubmit, initialSelectedSubjects: state.existingSubjectReasons.map(sr => sr.name), existingSubjectReasons: state.existingSubjectReasons, socket: socket, roomId: state.room.room, RemoveReservation: handleRemoveReservation, city: userCity })) : (_jsx(RoomUI, { room: state.room, unreadMessages: state.unreadMessages, handleTeacherButtonClick: handleNotificationButtonClick, handleNurseButtonClick: handleNotificationButtonClick, toggleChat: handleChatToggle, RemoveReservation: handleRemoveReservation, getUserNameByEmail: getUserNameByEmail, goBackToReason: handleGoBackToReason, updateRoomStatus: updateRoomStatus, city: userCity })) })) : (state.isChatVisible &&
                    state.room &&
                    state.user &&
                    state.selectedSubject &&
                    state.selectedSlotTime && (_jsx("div", { className: 'studentUI', children: _jsx("div", { className: "chat", children: _jsx(Chat, { room: state.room, user: state.user, selectedSubject: state.selectedSubject, selectedSlotTime: state.selectedSlotTime, displayReceiverAs: "student", allUsers: allUsersFront, onUpdateRoom: updateRoomStatus, onToggleChatVisibility: visible => updateState({ isChatVisible: visible }), onUpdateChatStatus: handleChatStatusUpdate, city: userCity }) }) }))) })] }));
};
export default RoomViewUI;
