import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// myklinik-frontend/src/components/Helsinki/HelsinkiUI/HeaderStudent.tsx
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../../styling/universal/global.css';
import '../../../styling/universal/header.css';
import myklinikio_logo from '../../../resource/images/logo/myklinikio_logo.png';
import { PiSignOutBold } from "react-icons/pi";
import Marquee from 'react-fast-marquee';
const HeaderStudent = ({ onOpenWhiteboard }) => {
    const [user, setUser] = useState(null);
    const [releases, setReleases] = useState({});
    const navigate = useNavigate();
    const fetchUserData = useCallback(async () => {
        const userID = localStorage.getItem('userID');
        if (!userID) {
            alert('User ID not found. Please log in again.');
            navigate('/');
            return;
        }
        try {
            const response = await fetch(`http://0.0.0.0:4010/database/users/users_helsinki/user/${userID}`);
            if (!response.ok)
                throw new Error('User not found');
            const data = await response.json();
            if (data.success)
                setUser(data.user);
            else
                throw new Error('Error fetching user data: ' + data.message);
        }
        catch (error) {
            console.error('Error fetching user data:', error);
            alert('User data not loaded. Please try again.');
        }
    }, [navigate]);
    const fetchReleases = useCallback(async () => {
        try {
            const response = await fetch(`http://0.0.0.0:4010/database/releases_helsinki`);
            if (!response.ok)
                throw new Error('Failed to fetch releases');
            const data = await response.json();
            const organized = data.ReleaseStudent?.reduce((acc, release) => {
                (acc[release.subject] = acc[release.subject] || []).push(release.content);
                return acc;
            }, {}) || {};
            setReleases(organized);
        }
        catch (error) {
            console.error('Error fetching releases:', error);
        }
    }, []);
    useEffect(() => {
        fetchUserData();
        fetchReleases();
        const intervalId = setInterval(fetchReleases, 3000);
        return () => clearInterval(intervalId);
    }, [fetchUserData, fetchReleases]);
    return (_jsxs("header", { className: "header", children: [_jsx(Link, { to: "/", children: _jsx("img", { src: myklinikio_logo, alt: "MyKlinik.io Logo", className: "logo" }) }), _jsx("div", { className: "headerbox", onClick: onOpenWhiteboard, children: _jsx(Marquee, { speed: 60, gradient: false, pauseOnHover: true, children: Object.entries(releases).map(([subject, contents], index) => (_jsxs("div", { className: "infotext", children: [_jsxs("span", { className: "subject", children: [subject, ":"] }), contents.map((content, contentIndex) => (_jsxs(React.Fragment, { children: [_jsx("span", { className: "content", children: content }), contentIndex < contents.length - 1 && _jsx("span", { className: "content-separator", children: "|" })] }, contentIndex))), index < Object.keys(releases).length - 1 && _jsx("span", { className: "separator" })] }, index))) }) }), _jsxs("div", { className: 'user', children: [_jsx("div", { className: "userdata", children: user && _jsxs("span", { children: [user.firstName, " ", user.lastName] }) }), _jsx("button", { className: "logout", onClick: () => { localStorage.clear(); navigate('/'); }, children: _jsx(PiSignOutBold, {}) })] })] }));
};
export default HeaderStudent;
