import { useEffect, useState } from 'react';
const City = "helsinki";
export const getApiConfig = () => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = env === 'production' ? '' : (process.env.REACT_APP_API_URL || 'http://localhost:4010');
    return {
        API_URL: apiUrl,
        DEBUG: process.env.REACT_APP_DEBUG === 'true',
        ENV: env
    };
};
const config = getApiConfig();
const fetchFromApi = async (endpoint) => {
    try {
        console.log('Fetching from:', `${config.API_URL}${endpoint}`);
        const response = await fetch(`${config.API_URL}${endpoint}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            console.error('Fetch error:', response.status, response.statusText);
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched data:', data);
        return data;
    }
    catch (error) {
        console.error(`Error fetching from ${endpoint}:`, error);
        throw error;
    }
};
export const useRoomStatusesDatabase = () => {
    const [RoomStatusesDatabase, setRoomStatusesDatabase] = useState([]);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchRooms = async () => {
            try {
                const data = await fetchFromApi(`/database/rooms_${City}`);
                setRoomStatusesDatabase(Array.isArray(data) ? data : []);
            }
            catch (error) {
                setError(error);
            }
        };
        fetchRooms();
    }, []);
    return { RoomStatusesDatabase, error };
};
export const useNeutralRoomsDatabase = () => {
    const [NeutralRoomsDatabase, setNeutralRooms] = useState([]);
    const [error, setError] = useState(null);
    useEffect(() => {
        fetchFromApi(`/database/rooms_${City}/neutral`)
            .then(data => setNeutralRooms(Array.isArray(data) ? data : []))
            .catch(err => {
            console.error('Error fetching neutral rooms:', err);
            setError(err);
        });
    }, []);
    return NeutralRoomsDatabase;
};
export const useReservedRoomsDatabase = () => {
    const [ReservedRoomsDatabase, setReservedRooms] = useState([]);
    const [error, setError] = useState(null);
    useEffect(() => {
        fetchFromApi(`/database/rooms_${City}/reserved`)
            .then(data => setReservedRooms(Array.isArray(data) ? data : []))
            .catch(err => {
            console.error('Error fetching reserved rooms:', err);
            setError(err);
        });
    }, []);
    return ReservedRoomsDatabase;
};
export const useJoinedRoomsDatabase = () => {
    const [JoinedRoomsDatabase, setJoinedRooms] = useState([]);
    const [error, setError] = useState(null);
    useEffect(() => {
        fetchFromApi(`/database/rooms_${City}/joined`)
            .then(data => setJoinedRooms(Array.isArray(data) ? data : []))
            .catch(err => {
            console.error('Error fetching joined rooms:', err);
            setError(err);
        });
    }, []);
    return JoinedRoomsDatabase;
};
export const useStartedRoomsDatabase = () => {
    const [StartedRoomsDatabase, setStartedRooms] = useState([]);
    const [error, setError] = useState(null);
    useEffect(() => {
        fetchFromApi(`/database/rooms_${City}/started`)
            .then(data => setStartedRooms(Array.isArray(data) ? data : []))
            .catch(err => {
            console.error('Error fetching started rooms:', err);
            setError(err);
        });
    }, []);
    return StartedRoomsDatabase;
};
export const useAlarmRoomsDatabase = () => {
    const [AlarmRoomsDatabase, setAlarmRooms] = useState([]);
    const [error, setError] = useState(null);
    useEffect(() => {
        fetchFromApi(`/database/rooms_${City}/alarms?notification=teacher`)
            .then(data => setAlarmRooms(Array.isArray(data) ? data : []))
            .catch(err => {
            console.error('Error fetching alarm rooms:', err);
            setError(err);
        });
    }, []);
    return AlarmRoomsDatabase;
};
