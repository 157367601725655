import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useMemo } from 'react';
import { getCurrentHelsinkiTime, formatTime } from '../../global/Time';
import { getCityConfig } from '../../../config/config';
import '../../../styling/universal/mainUI.css';
import '../../../styling/universal/global.css';
import '../../../styling/universal/forms.css';
import { IoArrowForwardCircle } from "react-icons/io5";
import { IoArrowBackCircle } from "react-icons/io5";
const slotTimes = ["08:00", "09:25", "12:10", "13:35"];
const Reason = ({ onReasonSubmit, initialSelectedSubjects, existingSubjectReasons, socket, roomId, RemoveReservation, city }) => {
    // Get city-specific configuration
    const cityConfig = useMemo(() => getCityConfig(city), [city]);
    // Get subject options based on city configuration
    const subjectOptions = useMemo(() => cityConfig.subjects.map(subject => ({
        value: subject,
        label: subject.charAt(0).toUpperCase() + subject.slice(1)
    })), [cityConfig.subjects]);
    const [selectedSubjects, setSelectedSubjects] = useState(initialSelectedSubjects);
    const [subjectReasons, setSubjectReasons] = useState(existingSubjectReasons);
    const [isSubjectsMenuOpen, setIsSubjectsMenuOpen] = useState(false);
    const [occupiedSlots, setOccupiedSlots] = useState(new Set());
    useEffect(() => {
        setSelectedSubjects(initialSelectedSubjects);
        setSubjectReasons(existingSubjectReasons);
        updateOccupiedSlots(existingSubjectReasons);
        const handleSubjectReasonsUpdate = (updatedReasons) => {
            setSubjectReasons(updatedReasons);
            updateOccupiedSlots(updatedReasons);
        };
        socket.on('subjectReasonsUpdate', handleSubjectReasonsUpdate);
        return () => {
            socket.off('subjectReasonsUpdate', handleSubjectReasonsUpdate);
        };
    }, [initialSelectedSubjects, existingSubjectReasons, socket]);
    const updateOccupiedSlots = (reasons) => {
        const newOccupiedSlots = new Set();
        reasons.forEach(reason => {
            if (reason.slotTime) {
                newOccupiedSlots.add(formatTime(reason.slotTime));
            }
        });
        setOccupiedSlots(newOccupiedSlots);
    };
    const toggleSubject = (subjectValue) => {
        setSelectedSubjects(prevSubjects => {
            if (prevSubjects.includes(subjectValue)) {
                handleRemoveSubject(subjectValue);
                return prevSubjects.filter(s => s !== subjectValue);
            }
            else {
                return [...prevSubjects, subjectValue];
            }
        });
    };
    const handleSlotTimeSelection = (subject, slotTime) => {
        const date = getCurrentHelsinkiTime();
        const [hours, minutes] = slotTime.split(':');
        date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
        setSubjectReasons(prev => {
            const existingIndex = prev.findIndex(item => item.name === subject && item.slotTime && formatTime(item.slotTime) === slotTime);
            let updatedReasons;
            if (existingIndex === -1) {
                updatedReasons = [...prev, { name: subject, slotTime: date, reason: "" }];
            }
            else {
                updatedReasons = prev.map((item, index) => index === existingIndex ? { ...item, slotTime: null, reason: "" } : item);
            }
            updateOccupiedSlots(updatedReasons);
            socket.emit('updateSubjectReasons', {
                roomId,
                updatedReasons,
                city
            });
            return updatedReasons;
        });
    };
    const handleRemoveSlotTime = (subject, slotTime) => {
        setSubjectReasons(prev => {
            const updatedReasons = prev.map(item => {
                if (item.name === subject && item.slotTime && item.slotTime.getTime() === slotTime.getTime()) {
                    return { ...item, slotTime: null, reason: "" };
                }
                return item;
            });
            updateOccupiedSlots(updatedReasons);
            socket.emit('updateSubjectReasons', {
                roomId,
                updatedReasons,
                city
            });
            return updatedReasons;
        });
    };
    const handleReasonChange = (subject, slotTime, reason) => {
        setSubjectReasons(prev => {
            const updatedReasons = prev.map(item => item.name === subject && item.slotTime && item.slotTime.getTime() === slotTime.getTime()
                ? { ...item, reason }
                : item);
            socket.emit('updateSubjectReasons', {
                roomId,
                updatedReasons,
                city
            });
            return updatedReasons;
        });
    };
    const handleRemoveSubject = (subjectToRemove) => {
        setSubjectReasons(prev => {
            const updatedReasons = prev.filter(item => item.name !== subjectToRemove);
            updateOccupiedSlots(updatedReasons);
            socket.emit('updateSubjectReasons', {
                roomId,
                updatedReasons,
                city
            });
            socket.emit('removeSubject', {
                roomId,
                subjectToRemove,
                city
            });
            return updatedReasons;
        });
    };
    const sortedSlotTimes = (items) => {
        const order = ["08:00", "09:25", "12:10", "13:35"];
        return items.sort((a, b) => {
            const timeA = a.slotTime ? formatTime(a.slotTime) : "";
            const timeB = b.slotTime ? formatTime(b.slotTime) : "";
            return order.indexOf(timeA) - order.indexOf(timeB);
        });
    };
    const handleSubmit = () => {
        const validSubjectReasons = subjectReasons.filter(item => item.slotTime !== null);
        if (validSubjectReasons.some(item => !item.reason.trim())) {
            alert("Syötä kaikille ohjauksille ajanvaraustiedot");
            return;
        }
        onReasonSubmit(validSubjectReasons);
        socket.emit('submitSubjectReasons', {
            roomId,
            subjectReasons: validSubjectReasons,
            city
        });
    };
    const isSlotTimeSelected = (subject, slotTime) => {
        return subjectReasons.some(item => item.name === subject && item.slotTime && formatTime(item.slotTime) === slotTime);
    };
    const renderSubjectCard = (subject) => {
        const subjectLabel = subject.charAt(0).toUpperCase() + subject.slice(1);
        return (_jsxs("div", { className: "cardbox", children: [_jsx("div", { className: "titlebox", children: _jsx("span", { className: 'title_text', children: subjectLabel }) }), _jsx("div", { className: 'function_group', children: _jsxs("div", { children: [_jsx("div", { className: "blackbox", children: slotTimes.map(slotTime => {
                                    const isDisabled = occupiedSlots.has(slotTime) && !isSlotTimeSelected(subject, slotTime);
                                    return (_jsx("button", { onClick: () => handleSlotTimeSelection(subject, slotTime), className: `pressit ${isSlotTimeSelected(subject, slotTime) ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`, title: 'Valitse ohjaus', disabled: isDisabled, children: slotTime }, slotTime));
                                }) }), sortedSlotTimes(subjectReasons.filter(item => item.name === subject && item.slotTime !== null))
                                .map((item, reasonIndex) => (_jsxs("div", { className: 'blackbox', children: [_jsx("div", { className: `slotTime ${item.slotTime && isSlotTimeSelected(subject, formatTime(item.slotTime)) ? 'selected' : ''}`, children: item.slotTime && formatTime(item.slotTime) }), _jsx("input", { className: "reason_input_text", value: item.reason, onChange: (e) => item.slotTime && handleReasonChange(subject, item.slotTime, e.target.value), placeholder: "Sy\u00F6t\u00E4 ajanvaraustiedot. Ei potilastietoja!" })] }, reasonIndex))), _jsx("span", { className: 'marginbox' })] }) })] }, subject));
    };
    return (_jsxs("div", { className: "studentUI", children: [_jsxs("div", { className: 'menubox', children: [_jsx("button", { type: "button", className: "dropdown_selection dropdown_selection_button", onClick: () => setIsSubjectsMenuOpen(!isSubjectsMenuOpen), children: selectedSubjects.length > 0 ? selectedSubjects.join(', ') : 'valitse päivän ohjaukset' }), isSubjectsMenuOpen && (_jsx("div", { className: "dropdown_menu", children: subjectOptions.map(option => (_jsxs("label", { className: "dropdown_option", children: [_jsx("input", { type: "checkbox", checked: selectedSubjects.includes(option.value), onChange: () => toggleSubject(option.value) }), option.label] }, option.value))) }))] }), selectedSubjects.length > 0 ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "card_group", children: selectedSubjects.map(renderSubjectCard) }), _jsx("div", { className: 'bottombox', children: _jsx("button", { title: 'seuraava', className: "menubutton_light", onClick: handleSubmit, children: _jsx(IoArrowForwardCircle, {}) }) })] })) : (_jsx("div", { className: 'bottombox', children: _jsx("button", { title: 'edellinen: peru valinta ja valitse uudelleen', className: "menubutton_light", onClick: RemoveReservation, children: _jsx(IoArrowBackCircle, {}) }) }))] }));
};
export default React.memo(Reason);
