import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import { useAllUsersDatabase } from '../../global/FetchUsers';
import { getCurrentHelsinkiTime } from '../../global/Time';
import '../../../styling/universal/global.css';
import '../../../styling/universal/mainUI.css';
import '../../../styling/universal/notificationUI.css';
import { CONFIG, getCityConfig, getEndpoints } from '../../../config/config';
// API helper function
const apiRequest = async (endpoint, options = {}) => {
    try {
        const response = await fetch(endpoint, {
            ...options,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                ...options.headers,
            },
            credentials: 'include'
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (CONFIG.DEBUG) {
            console.log(`API Response from ${endpoint}:`, data);
        }
        return data;
    }
    catch (error) {
        if (CONFIG.DEBUG) {
            console.error(`API Error for ${endpoint}:`, error);
        }
        throw error;
    }
};
const NotificationUI = () => {
    const { city } = useParams();
    const userCity = city;
    const cityConfig = useMemo(() => getCityConfig(userCity), [userCity]);
    const cityEndpoints = useMemo(() => getEndpoints(userCity), [userCity]);
    const socket = useMemo(() => io(CONFIG.SOCKET_URL, {
        transports: ['websocket'],
        reconnectionAttempts: parseInt(process.env.REACT_APP_SOCKET_RECONNECT_ATTEMPTS || '5'),
        reconnectionDelay: parseInt(process.env.REACT_APP_SOCKET_RECONNECT_DELAY || '1000'),
        autoConnect: true,
        auth: { token: localStorage.getItem('token') }
    }), []);
    const [statuses, setStatuses] = useState({});
    const [currentTime, setCurrentTime] = useState(getCurrentHelsinkiTime());
    const [user, setUser] = useState(null);
    const { users: allUsers } = useAllUsersDatabase(userCity);
    const allUsersFront = useMemo(() => {
        return (allUsers || []).map(user => ({
            _id: user._id || '',
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password || '',
            city: user.city || '',
            role: user.role
        }));
    }, [allUsers]);
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(getCurrentHelsinkiTime());
        }, 1000);
        return () => clearInterval(timer);
    }, []);
    useEffect(() => {
        const loadInitialData = async () => {
            const userID = localStorage.getItem('userID');
            if (!userID) {
                alert('User ID not found. Please log in again.');
                return;
            }
            try {
                const userData = await apiRequest(`${cityEndpoints.USER}/${userID}`);
                if (userData.success) {
                    setUser(userData.user);
                }
                await fetchRoomData();
            }
            catch (error) {
                console.error('Error loading initial data:', error);
                alert('Failed to load initial data. Please try again.');
            }
        };
        loadInitialData();
        const handleRoomStatusUpdate = (updatedStatuses) => {
            setStatuses(prevStatuses => {
                const newStatuses = { ...prevStatuses };
                Object.entries(updatedStatuses).forEach(([room, updatedRoom]) => {
                    if (newStatuses[room]) {
                        newStatuses[room] = {
                            ...newStatuses[room],
                            ...updatedRoom,
                            subjects: updatedRoom.subjects || newStatuses[room].subjects
                        };
                    }
                });
                return newStatuses;
            });
        };
        socket.on(`roomStatusUpdate${userCity.charAt(0).toUpperCase()}${userCity.slice(1)}`, handleRoomStatusUpdate);
        return () => {
            socket.off(`roomStatusUpdate${userCity.charAt(0).toUpperCase()}${userCity.slice(1)}`, handleRoomStatusUpdate);
        };
    }, [cityEndpoints, socket, userCity]);
    const fetchRoomData = async () => {
        try {
            const roomStatuses = await apiRequest(cityEndpoints.ROOMS);
            setStatuses(prevStatuses => {
                const updatedStatuses = {};
                roomStatuses.forEach((roomStatus) => {
                    updatedStatuses[roomStatus.room] = roomStatus;
                });
                return { ...prevStatuses, ...updatedStatuses };
            });
        }
        catch (error) {
            console.error('Error fetching Room statuses:', error);
        }
    };
    const getStudentUserNameByEmail = (email) => {
        const user = allUsersFront.find(user => user.email === email);
        return user ? `${user.firstName} ${user.lastName}` : 'Unknown';
    };
    const formatStudentName = (email) => {
        if (!email)
            return 'Unknown';
        const fullName = getStudentUserNameByEmail(email);
        const nameParts = fullName.split(' ');
        if (nameParts.length < 2)
            return fullName;
        const lastName = nameParts.pop();
        const firstName = nameParts.join(' ');
        return `${firstName} ${lastName}`;
    };
    const getTeacherUserNameByEmail = (email) => {
        const user = allUsersFront.find(user => user.email === email);
        return user ? `${user.firstName}` : 'Unknown';
    };
    const groupedNotifications = useMemo(() => {
        const grouped = {};
        Object.entries(statuses).forEach(([room, roomData]) => {
            roomData.subjects.forEach(subject => {
                subject.subjectSpecificTime.forEach(sst => {
                    if (sst.status === 'started' && sst.notification === 'teacher' && sst.notificationTime) {
                        if (!grouped[subject.name]) {
                            grouped[subject.name] = [];
                        }
                        const notificationDate = new Date(sst.notificationTime);
                        const helsinkiNotificationDate = new Date(notificationDate.toLocaleString("en-US", { timeZone: cityConfig.timezone }));
                        const diffMilliseconds = currentTime.getTime() - helsinkiNotificationDate.getTime();
                        const diffMinutes = Math.max(0, Math.floor(diffMilliseconds / 60000));
                        grouped[subject.name].push({ room, subject, sst, diffMinutes });
                    }
                });
            });
        });
        // Sort notifications within each subject
        Object.keys(grouped).forEach(subject => {
            grouped[subject].sort((a, b) => b.diffMinutes - a.diffMinutes);
        });
        return grouped;
    }, [statuses, currentTime, cityConfig.timezone]);
    const groupedNotificationsAssistant = useMemo(() => {
        const grouped = {};
        Object.entries(statuses).forEach(([room, roomData]) => {
            roomData.subjects.forEach(subject => {
                subject.subjectSpecificTime.forEach(sst => {
                    if (sst.status === 'started' && sst.notification === 'nurse' && sst.notificationTime) {
                        if (!grouped[subject.name]) {
                            grouped[subject.name] = [];
                        }
                        const notificationDate = new Date(sst.notificationTime);
                        const helsinkiNotificationDate = new Date(notificationDate.toLocaleString("en-US", { timeZone: cityConfig.timezone }));
                        const diffMilliseconds = currentTime.getTime() - helsinkiNotificationDate.getTime();
                        const diffMinutes = Math.max(0, Math.floor(diffMilliseconds / 60000));
                        grouped[subject.name].push({ room, subject, sst, diffMinutes });
                    }
                });
            });
        });
        Object.keys(grouped).forEach(subject => {
            grouped[subject].sort((a, b) => b.diffMinutes - a.diffMinutes);
        });
        return grouped;
    }, [statuses, currentTime, cityConfig.timezone]);
    const renderNotificationUnit = ({ room, sst, diffMinutes }, index) => {
        return (_jsxs("div", { className: "notification_unit", children: [_jsx("div", { className: "notification_wait", title: `${diffMinutes} min`, children: `${index + 1}.` }), _jsx("div", { className: "notification_roombox", title: formatStudentName(sst.reservedBy || ''), children: _jsx("div", { className: 'notification_room', children: room }) }), _jsx("span", { className: "notification_student", title: sst.reason, children: getTeacherUserNameByEmail(sst.joinedBy) })] }, `${room}_${sst.slotTime}`));
    };
    const renderNotificationGroup = (notifications) => {
        return (_jsx("div", { className: "notification_column", children: notifications.map((notification, index) => renderNotificationUnit(notification, index)) }));
    };
    return (_jsx("div", { className: 'notificationUI', children: _jsx("div", { className: "notificationTEACHER", children: _jsxs("div", { className: "notificationbox", children: [(() => {
                        const allNurseNotifications = Object.values(groupedNotificationsAssistant)
                            .flat()
                            .sort((a, b) => b.diffMinutes - a.diffMinutes);
                        if (allNurseNotifications.length > 0) {
                            return (_jsxs("div", { className: "notification_subject", children: [_jsx("div", { className: "notification_titlebox", children: _jsx("span", { children: "avustajapyynn\u00F6t" }) }), _jsx("div", { className: "notification_functionbox", children: _jsx("div", { className: "notification_column", children: allNurseNotifications.map((notification, index) => (_jsxs("div", { className: "notification_unit", children: [_jsx("div", { className: "notification_wait", title: `${notification.diffMinutes} min`, children: `${index + 1}.` }), _jsx("div", { className: "notification_roombox", children: _jsx("div", { className: 'notification_room_assistant', children: notification.room }) }), _jsx("span", { className: "notification_student", title: notification.sst.reason })] }, `${notification.room}_${notification.sst.slotTime}`))) }) }), _jsx("div", { className: 'notification_bottombox' })] }));
                        }
                        return null;
                    })(), cityConfig.subjects.map(subjectName => {
                        const notifications = groupedNotifications[subjectName] || [];
                        if (notifications.length === 0)
                            return null;
                        return (_jsxs("div", { className: "notification_subject", children: [_jsx("div", { className: "notification_titlebox", children: _jsx("span", { children: subjectName }) }), _jsx("div", { className: "notification_functionbox", children: _jsx("div", { className: "notification_column", children: renderNotificationGroup(notifications) }) }), _jsx("div", { className: 'notification_bottombox' })] }, subjectName));
                    })] }) }) }));
};
export default NotificationUI;
