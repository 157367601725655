import { jsx as _jsx } from "react/jsx-runtime";
// myklinik-frontend/src/components/global/LoginAuth.tsx
import { createContext, useState, useContext } from 'react';
const AuthContext = createContext(undefined);
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
    const login = (token, role, userID, email) => {
        localStorage.setItem('token', token);
        localStorage.setItem('role', role);
        localStorage.setItem('userID', userID);
        localStorage.setItem('email', email);
        setIsAuthenticated(true);
    };
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('userID');
        localStorage.removeItem('email');
        setIsAuthenticated(false);
    };
    return (_jsx(AuthContext.Provider, { value: { isAuthenticated, login, logout }, children: children }));
};
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
// For backward compatibility
export const LoginAuth = useAuth;
