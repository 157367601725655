import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef, useCallback } from 'react';
import { PiPaperPlaneTiltFill } from "react-icons/pi";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import io from 'socket.io-client';
import { CONFIG, DEFAULT_CITY, getCityConfig } from '../../../src/config/config';
// Get city-specific configuration
const cityConfig = getCityConfig(DEFAULT_CITY);
// Initialize socket with configuration
const socket = io(CONFIG.SOCKET_URL, {
    transports: ['websocket'],
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    autoConnect: true,
    auth: {
        token: localStorage.getItem('token')
    }
});
const Chat = ({ room, user, selectedSubject, selectedSlotTime, displayReceiverAs, allUsers, onUpdateRoom, onToggleChatVisibility, onUpdateChatStatus }) => {
    // Refs
    const messagesContainerRef = useRef(null);
    // State management
    const [state, setState] = useState({
        messageInput: '',
        messages: [],
        isChatOpen: false,
        isLoading: true,
        error: null
    });
    const updateState = (updates) => {
        setState(prev => ({ ...prev, ...updates }));
    };
    // Message handling
    const updateMessages = useCallback(() => {
        try {
            const selectedSubjectData = room.subjects.find(subject => subject.name === selectedSubject);
            const selectedSubjectSpecificTime = selectedSubjectData?.subjectSpecificTime.find(sst => new Date(sst.slotTime).getTime() === new Date(selectedSlotTime).getTime());
            if (selectedSubjectSpecificTime) {
                const updatedMessages = [
                    ...(selectedSubjectSpecificTime.messageStudent || []),
                    ...(selectedSubjectSpecificTime.messageTeacher || [])
                ].sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
                updateState({
                    messages: updatedMessages,
                    isLoading: false
                });
            }
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error updating messages:', error);
            }
            updateState({
                error: 'Failed to update messages',
                isLoading: false
            });
        }
    }, [room, selectedSubject, selectedSlotTime]);
    // Socket event handlers
    useEffect(() => {
        const handleChatUpdate = (updatedRoom) => {
            if (CONFIG.DEBUG) {
                console.log('Received chatUpdate:', updatedRoom);
            }
            if (updatedRoom.room === room.room) {
                onUpdateRoom(updatedRoom);
                updateMessages();
            }
        };
        const handleMessageReceived = (updatedRoom) => {
            if (CONFIG.DEBUG) {
                console.log('Received messageReceived:', updatedRoom);
            }
            if (updatedRoom.room === room.room) {
                onUpdateRoom(updatedRoom);
                updateMessages();
                scrollToBottom();
            }
        };
        const initializeChat = () => {
            if (!state.isChatOpen) {
                if (CONFIG.DEBUG) {
                    console.log('Opening chat');
                }
                socket.emit('toggleChat', {
                    room: room.room,
                    subjectName: selectedSubject,
                    slotTime: selectedSlotTime.toISOString(),
                    chatStatus: 'on',
                    userRole: displayReceiverAs
                });
                socket.emit('markMessagesAsOpened', {
                    room: room.room,
                    subjectName: selectedSubject,
                    slotTime: selectedSlotTime.toISOString(),
                    userRole: displayReceiverAs
                });
                updateState({ isChatOpen: true });
            }
        };
        initializeChat();
        updateMessages();
        scrollToBottom();
        socket.on('chatUpdate', handleChatUpdate);
        socket.on('messageReceived', handleMessageReceived);
        socket.on('error', (error) => {
            if (CONFIG.DEBUG) {
                console.error('Socket error:', error);
            }
            updateState({ error: 'Connection error. Please try again.' });
        });
        return () => {
            socket.off('chatUpdate');
            socket.off('messageReceived');
            socket.off('error');
        };
    }, [room.room, selectedSubject, selectedSlotTime, displayReceiverAs, onUpdateRoom, updateMessages, state.isChatOpen]);
    // UI helpers
    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    };
    // Event handlers
    const handleCloseChat = () => {
        if (CONFIG.DEBUG) {
            console.log('Closing chat');
        }
        socket.emit('toggleChat', {
            room: room.room,
            subjectName: selectedSubject,
            slotTime: selectedSlotTime.toISOString(),
            chatStatus: 'off',
            userRole: displayReceiverAs
        });
        updateState({ isChatOpen: false });
        onUpdateChatStatus('off');
        onToggleChatVisibility(false);
    };
    const handleSendMessage = () => {
        if (!state.messageInput.trim() || !user)
            return;
        try {
            const selectedSubjectSpecificTime = room.subjects
                .find(subject => subject.name === selectedSubject)
                ?.subjectSpecificTime.find(sst => new Date(sst.slotTime).getTime() === new Date(selectedSlotTime).getTime());
            const newMessage = {
                sender: user.email,
                content: state.messageInput.trim(),
                timestamp: new Date(),
                status: 'unopened'
            };
            socket.emit('sendMessage', {
                room: room.room,
                subjectName: selectedSubject,
                slotTime: selectedSlotTime.toISOString(),
                message: newMessage,
                senderRole: displayReceiverAs,
                chatStatus: {
                    student: selectedSubjectSpecificTime?.chatStudent,
                    teacher: selectedSubjectSpecificTime?.chatTeacher
                }
            });
            updateState({ messageInput: '' });
        }
        catch (error) {
            if (CONFIG.DEBUG) {
                console.error('Error sending message:', error);
            }
            updateState({ error: 'Failed to send message. Please try again.' });
        }
    };
    // Utility functions
    const getUserNameByEmail = (email) => {
        const user = allUsers.find(user => user.email === email);
        return user ? `${user.firstName} ${user.lastName}` : 'Unknown';
    };
    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    };
    // Computed values
    const selectedSubjectData = room.subjects.find(subject => subject.name === selectedSubject);
    const selectedSubjectSpecificTime = selectedSubjectData?.subjectSpecificTime.find(sst => new Date(sst.slotTime).getTime() === new Date(selectedSlotTime).getTime());
    const receiverName = displayReceiverAs === 'student'
        ? getUserNameByEmail(selectedSubjectSpecificTime?.joinedBy || '')
        : getUserNameByEmail(selectedSubjectSpecificTime?.reservedBy || '');
    // Loading and error states
    if (state.isLoading) {
        return _jsx("div", { className: "loading", children: "Loading chat..." });
    }
    if (state.error) {
        return _jsx("div", { className: "error", children: state.error });
    }
    // Main render
    return (_jsx("div", { className: 'chatUI', children: _jsxs("div", { className: "chatbox", children: [_jsx("div", { className: "titlebox_chat", children: _jsx("span", { className: 'title_text', children: selectedSubject }) }), _jsx("div", { className: "receiver", children: receiverName }), _jsx("div", { className: "messages", ref: messagesContainerRef, children: state.messages
                        .reduce((acc, msg, index, array) => {
                        if (index === 0 || msg.sender !== array[index - 1].sender) {
                            acc.push([msg]);
                        }
                        else {
                            acc[acc.length - 1].push(msg);
                        }
                        return acc;
                    }, [])
                        .map((group, groupIndex) => (_jsxs("div", { className: `message-group ${group[0].sender === user?.email ? 'teacher' : 'student'}`, children: [_jsx("div", { className: "name", children: getUserNameByEmail(group[0].sender) }), group.map((msg, msgIndex) => (_jsxs("div", { className: "message", children: [_jsx("div", { className: `content ${msg.status}`, children: msg.content }), _jsx("div", { className: "timestamp", children: formatTimestamp(msg.timestamp) })] }, msgIndex)))] }, groupIndex))) }), _jsxs("div", { className: "message-input", children: [_jsx("input", { type: "text", value: state.messageInput, onChange: (e) => updateState({ messageInput: e.target.value }), onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSendMessage();
                                }
                            }, placeholder: "Viesti..." }), _jsx("button", { className: "send", onClick: handleSendMessage, disabled: !state.messageInput.trim(), children: _jsx(PiPaperPlaneTiltFill, {}) })] }), _jsx("div", { className: 'buttonbox', children: _jsx("button", { title: 'close chat', className: "menubutton_dark", onClick: handleCloseChat, children: _jsx(IoArrowBackCircleSharp, {}) }) })] }) }));
};
export default Chat;
