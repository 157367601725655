import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { IoArrowForwardCircle } from "react-icons/io5";
import { PiXCircleFill } from "react-icons/pi";
import universalProfilePic from '../../../resource/images/profiles/HelsinkiPictures/Students/universal.png';
import { formatTime } from '../../global/Time';
const Ohjauslista = ({ statuses, handleBackToReserved, handleJoinRoom, getUserNameByEmail, toggleReasonHeader, showReasonHeader, toggleRoomsVisibility, isChatVisible, subjects, userEmail }) => {
    const [selectedSubjects, setSelectedSubjects] = useState(["all"]);
    const [localReasonVisibility, setLocalReasonVisibility] = useState({});
    const [isSubjectsMenuOpen, setIsSubjectsMenuOpen] = useState(false);
    const toggleLocalReason = (key) => {
        setLocalReasonVisibility(prev => ({
            ...prev,
            [key]: !prev[key]
        }));
    };
    const formatStudentName = (email) => {
        const fullName = getUserNameByEmail(email);
        const [firstName, lastName] = fullName.split(' ');
        return `${lastName} ${firstName}`;
    };
    const sortRooms = (rooms) => {
        const statusOrder = ['reserved', 'joined', 'started', 'finished'];
        return rooms.sort((a, b) => {
            const statusDiff = statusOrder.indexOf(a.sst.status) - statusOrder.indexOf(b.sst.status);
            if (statusDiff !== 0)
                return statusDiff;
            const timeDiff = new Date(a.sst.slotTime).getTime() - new Date(b.sst.slotTime).getTime();
            if (timeDiff !== 0)
                return timeDiff;
            const lastNameA = formatStudentName(a.sst.reservedBy || '').split(' ')[0];
            const lastNameB = formatStudentName(b.sst.reservedBy || '').split(' ')[0];
            return lastNameA.localeCompare(lastNameB);
        });
    };
    const toggleSubject = (subjectValue) => {
        setSelectedSubjects(prevSubjects => {
            if (subjectValue === "all") {
                return prevSubjects.includes("all") ? [] : ["all"];
            }
            else {
                const newSubjects = prevSubjects.filter(s => s !== "all");
                if (newSubjects.includes(subjectValue)) {
                    return newSubjects.filter(s => s !== subjectValue);
                }
                else {
                    return [...newSubjects, subjectValue];
                }
            }
        });
    };
    const ensureDate = (date) => {
        return date instanceof Date ? date : new Date(date);
    };
    const groupRoomsBySubjectAndTime = useMemo(() => {
        const groupedRooms = {};
        const allSlotTimes = new Set();
        const activeSubjects = new Set();
        Object.entries(statuses).forEach(([room, roomStatus]) => {
            roomStatus.subjects.forEach(subjectItem => {
                const subjectName = subjectItem.name.toLowerCase();
                subjectItem.subjectSpecificTime.forEach(sst => {
                    if (['reserved', 'joined', 'started', 'finished'].includes(sst.status)) {
                        if (!groupedRooms[subjectName]) {
                            groupedRooms[subjectName] = {};
                        }
                        const slotTime = formatTime(sst.slotTime);
                        allSlotTimes.add(slotTime);
                        activeSubjects.add(subjectName);
                        if (!groupedRooms[subjectName][slotTime]) {
                            groupedRooms[subjectName][slotTime] = [];
                        }
                        groupedRooms[subjectName][slotTime].push({ room, subject: subjectItem, sst });
                    }
                });
            });
        });
        const sortedSlotTimes = Array.from(allSlotTimes).sort();
        const sortedActiveSubjects = Array.from(activeSubjects).sort((a, b) => {
            if (a === 'perushoito')
                return -1;
            if (b === 'perushoito')
                return 1;
            return a.localeCompare(b);
        });
        Object.keys(groupedRooms).forEach(subject => {
            sortedSlotTimes.forEach(slotTime => {
                if (groupedRooms[subject][slotTime]) {
                    groupedRooms[subject][slotTime] = sortRooms(groupedRooms[subject][slotTime]);
                }
            });
        });
        return { groupedRooms, sortedSlotTimes, activeSubjects: sortedActiveSubjects };
    }, [statuses]);
    const subjectOptions = useMemo(() => [
        { value: "all", label: "kaikki ohjauslistat" },
        ...groupRoomsBySubjectAndTime.activeSubjects.map(subject => ({
            value: subject,
            label: subject
        }))
    ], [groupRoomsBySubjectAndTime.activeSubjects]);
    const sortSelectedSubjects = (subjects) => {
        return subjects.sort((a, b) => {
            if (a === 'perushoito')
                return -1;
            if (b === 'perushoito')
                return 1;
            return a.localeCompare(b);
        });
    };
    const getRoomStatusTitle = (roomStatus, joinedBy) => {
        switch (roomStatus) {
            case 'reserved':
                return "Valitse ohjaus";
            case 'joined':
                return `Ohjaus valittuna: ${getUserNameByEmail(joinedBy || '')}`;
            case 'started':
                return `Aloituslupa: ${getUserNameByEmail(joinedBy || '')}`;
            case 'finished':
                return `Ohjaus valmis: ${getUserNameByEmail(joinedBy || '')}`;
            default:
                return "";
        }
    };
    const renderSubjectCard = (subject, slotTimes, sortedSlotTimes) => (_jsxs("div", { className: "cardbox", children: [_jsx("div", { className: "titlebox", children: _jsx("span", { className: 'title_text', children: subject.toLowerCase() }) }), sortedSlotTimes.map(slotTime => {
                const rooms = slotTimes[slotTime] || [];
                if (rooms.length === 0)
                    return null;
                return (_jsxs("div", { children: [_jsx("div", { className: "marginbox", children: slotTime }), rooms.map(({ room, subject: subjectItem, sst }) => {
                            const subjectKey = `${room}_${subjectItem.name}_${slotTime}`;
                            const roomStatus = sst.status;
                            const reservedBy = sst.reservedBy;
                            const reason = sst.reason;
                            const slotTimeISO = ensureDate(sst.slotTime).toISOString();
                            const isReasonVisible = localReasonVisibility[subjectKey] || false;
                            return (_jsxs("div", { className: "function_group", children: [_jsxs("div", { className: `room_container ${roomStatus}`, children: [((roomStatus === 'started' && sst.joinedBy === userEmail) || (roomStatus === 'joined' && sst.joinedBy === userEmail)) && (_jsx("div", { className: 'room_selected_box', children: roomStatus === 'joined' && sst.joinedBy === userEmail && (_jsx("button", { className: "remove", title: "Palauta", onClick: () => handleBackToReserved(room, subjectItem.name, ensureDate(sst.slotTime)), children: _jsx(PiXCircleFill, {}) })) })), _jsx("div", { className: `room_list ${roomStatus}`, onClick: () => {
                                                    if (roomStatus === 'reserved') {
                                                        const slotTimeDate = new Date(sst.slotTime);
                                                        handleJoinRoom(room, subjectItem.name, slotTimeDate);
                                                    }
                                                }, title: getRoomStatusTitle(roomStatus, sst.joinedBy), children: room }), reservedBy && (_jsx("span", { className: "student_name", onClick: () => {
                                                    toggleLocalReason(subjectKey);
                                                    toggleReasonHeader(room, subjectItem.name, ensureDate(sst.slotTime));
                                                }, title: 'Näytä ajanvaraustiedot', children: formatStudentName(reservedBy) }))] }), isReasonVisible && (_jsxs("div", { className: "infocontainer", children: [_jsx("img", { src: universalProfilePic, alt: "Profile", className: "profilepicture" }), reason && (_jsxs("div", { className: "infoheader", children: [slotTime, ": ", reason] })), (roomStatus === 'joined' || roomStatus === 'started') && sst.joinedBy && (_jsxs("div", { className: "infoheader bold", children: ["Ohjaus: ", getUserNameByEmail(sst.joinedBy)] }))] }))] }, subjectKey));
                        })] }, `${subject}_${slotTime}`));
            }), _jsx("span", { className: 'marginbox' })] }, subject));
    const hasActiveSubjects = groupRoomsBySubjectAndTime.activeSubjects.length > 0;
    return (_jsx("div", { className: "teacherUI", children: hasActiveSubjects ? (_jsxs("div", { children: [_jsxs("div", { className: "menubox", children: [_jsx("button", { type: "button", className: "dropdown_selection dropdown_selection_button", onClick: () => setIsSubjectsMenuOpen(!isSubjectsMenuOpen), children: selectedSubjects.includes("all")
                                ? "kaikki ohjauslistat"
                                : selectedSubjects.length > 0
                                    ? sortSelectedSubjects([...selectedSubjects]).join(', ')
                                    : 'valitse päivän ohjaukset' }), isSubjectsMenuOpen && (_jsx("div", { className: "dropdown_menu", children: subjectOptions.map(option => (_jsxs("label", { className: "dropdown_option", children: [_jsx("input", { type: "checkbox", checked: selectedSubjects.includes(option.value), onChange: () => toggleSubject(option.value) }), option.label] }, option.value))) }))] }), _jsx("div", { className: "card_group", children: groupRoomsBySubjectAndTime.activeSubjects.map(subject => {
                        if (!selectedSubjects.includes("all") && !selectedSubjects.includes(subject))
                            return null;
                        return renderSubjectCard(subject, groupRoomsBySubjectAndTime.groupedRooms[subject], groupRoomsBySubjectAndTime.sortedSlotTimes);
                    }) }), _jsx("div", { className: 'bottombox', children: _jsx("button", { title: 'seuraava', className: "menubutton_light", onClick: toggleRoomsVisibility, children: _jsx(IoArrowForwardCircle, {}) }) })] })) : (_jsx("div", { className: 'infobox', children: "ei ohjauksia" })) }));
};
export default React.memo(Ohjauslista);
