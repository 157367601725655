import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// myklinik-frontend/src/components/Helsinki/HelsinkiUI/ohjattavat.tsx
import { useState, useMemo } from 'react';
import { PiXCircleFill, PiCheckCircleFill, PiPaperPlaneTiltFill, } from "react-icons/pi";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { formatTime } from '../../global/Time';
const Ohjattavat = ({ statuses, handleBackToReserved, handleRoomFinished, handleStartRoom, getUserNameByEmail, toggleChat, toggleRoomsVisibility, isChatVisible, formatNotificationTime, userEmail, updateRoomStatus }) => {
    const [visibleInfo, setVisibleInfo] = useState({});
    const [selectedTimeOfDay, setSelectedTimeOfDay] = useState("all");
    const formatStudentName = (email) => {
        if (!email)
            return 'Unknown';
        const fullName = getUserNameByEmail(email);
        const nameParts = fullName.split(' ');
        if (nameParts.length < 2)
            return fullName;
        const lastName = nameParts.pop();
        const firstName = nameParts.join(' ');
        return `${lastName} ${firstName}`;
    };
    const ensureDate = (date) => {
        return date instanceof Date ? date : new Date(date);
    };
    const sortRoomsByPriority = (rooms) => {
        return rooms.sort((a, b) => {
            const aHasNotification = a.sst.notification === 'teacher' && a.sst.notificationTime;
            const bHasNotification = b.sst.notification === 'teacher' && b.sst.notificationTime;
            if (aHasNotification && !bHasNotification)
                return -1;
            if (!aHasNotification && bHasNotification)
                return 1;
            if (aHasNotification && bHasNotification) {
                const aWaitTime = new Date().getTime() - new Date(a.sst.notificationTime).getTime();
                const bWaitTime = new Date().getTime() - new Date(b.sst.notificationTime).getTime();
                return bWaitTime - aWaitTime; // Longer wait time comes first
            }
            return 0;
        });
    };
    const groupRoomsByTimeOfDay = (rooms) => {
        return rooms.reduce((acc, room) => {
            const slotTime = formatTime(room.sst.slotTime);
            const timeOfDay = slotTime < "12:00" ? "morning" : "evening";
            if (!acc[timeOfDay])
                acc[timeOfDay] = [];
            acc[timeOfDay].push(room);
            return acc;
        }, {});
    };
    const joinedAndStartedRooms = useMemo(() => {
        return Object.entries(statuses).flatMap(([room, roomData]) => roomData.subjects.flatMap(subject => subject.subjectSpecificTime.filter(sst => (sst.status === 'joined' || sst.status === 'started') &&
            sst.joinedBy === userEmail).map(sst => ({ room, subject, sst }))));
    }, [statuses, userEmail]);
    const groupedRooms = useMemo(() => {
        const grouped = groupRoomsByTimeOfDay(joinedAndStartedRooms);
        Object.keys(grouped).forEach(timeOfDay => {
            grouped[timeOfDay] = sortRoomsByPriority(grouped[timeOfDay]);
        });
        return grouped;
    }, [joinedAndStartedRooms]);
    const toggleInfo = (key) => {
        setVisibleInfo(prev => ({
            ...prev,
            [key]: !prev[key]
        }));
    };
    const renderRoom = ({ room, subject, sst }) => {
        const subjectKey = `${room}_${subject.name}_${ensureDate(sst.slotTime).toISOString()}`;
        const hasUnreadMessages = sst.messageStudent.some(msg => msg.status === 'unopened') && sst.chatTeacher === 'off';
        const roomStatus = sst.status;
        const notification = sst.notification;
        const notificationTime = sst.notificationTime;
        const reservedBy = sst.reservedBy;
        const reason = sst.reason;
        const isInfoVisible = visibleInfo[subjectKey] || false;
        const slotTime = formatTime(sst.slotTime);
        return (_jsxs("div", { className: "cardbox", children: [_jsx("div", { children: _jsxs("div", { className: `room_container ${roomStatus} ${roomStatus === 'started' && notification === 'teacher' ? 'notification' : ''}`, children: [roomStatus === 'started' && notification === 'teacher' ? (_jsx("div", { className: "wait", children: formatNotificationTime(notificationTime).icon })) : roomStatus === 'joined' ? (_jsx("button", { className: "remove", title: "Palauta", onClick: () => handleBackToReserved(room, subject.name, ensureDate(sst.slotTime)), children: _jsx(PiXCircleFill, {}) })) : (_jsx("button", { className: "check", title: "Ohjaus valmis", onClick: () => handleRoomFinished(room, subject.name, ensureDate(sst.slotTime)), children: _jsx(PiCheckCircleFill, {}) })), _jsx("div", { className: `room_selected ${roomStatus} ${roomStatus === 'started' && notification === 'teacher' ? 'notification' : ''}`, onClick: () => {
                                    if (roomStatus === 'joined') {
                                        handleStartRoom(room, subject.name, ensureDate(sst.slotTime));
                                    }
                                }, title: roomStatus === 'joined' ? 'Anna aloituslupa' : roomStatus === 'started' ? 'Aloituslupa annettu' : roomStatus, children: room }), _jsx("span", { className: "student_name", onClick: () => toggleInfo(subjectKey), title: reason ? `${reason}` : 'No reason provided', children: formatStudentName(reservedBy) }), _jsx("button", { className: `msg ${hasUnreadMessages ? 'flashing' : ''}`, onClick: () => handleChatButtonClick(room, subject, sst), children: _jsx(PiPaperPlaneTiltFill, {}) })] }) }), isInfoVisible && (_jsxs("div", { className: "infoheader bold", children: [slotTime, " ", subject.name, ": ", reason] }))] }, subjectKey));
    };
    const timeOfDayOptions = [
        { value: "all", label: "08:00 / 09:25 / 12:10 / 13:35" },
        { value: "morning", label: "08:00 / 09:25" },
        { value: "evening", label: "12:10 / 13:35" }
    ];
    const getTimeOfDayTitle = (timeOfDay) => {
        switch (timeOfDay) {
            case "morning":
                return "aamupäivä";
            case "evening":
                return "iltapäivä";
            default:
                return timeOfDay;
        }
    };
    const handleChatButtonClick = (room, subject, sst) => {
        const updatedRoom = { ...statuses[room] };
        const subjectIndex = updatedRoom.subjects.findIndex(s => s.name === subject.name);
        if (subjectIndex !== -1) {
            const sstIndex = updatedRoom.subjects[subjectIndex].subjectSpecificTime.findIndex(s => new Date(s.slotTime).getTime() === new Date(sst.slotTime).getTime());
            if (sstIndex !== -1) {
                const updatedSst = updatedRoom.subjects[subjectIndex].subjectSpecificTime[sstIndex];
                updatedSst.chatTeacher = 'on';
                // Mark all unopened messages as opened when opening the chat
                updatedSst.messageStudent = updatedSst.messageStudent.map(msg => msg.status === 'unopened' ? { ...msg, status: 'opened' } : msg);
                updateRoomStatus(updatedRoom);
            }
        }
        toggleChat(room, subject.name, ensureDate(sst.slotTime));
    };
    const renderTimeOfDayCard = (timeOfDay) => {
        const rooms = groupedRooms[timeOfDay] || [];
        if (rooms.length === 0) {
            return (_jsxs("div", { className: "cardbox", children: [_jsx("div", { className: "titlebox", children: _jsx("span", { className: 'title_text', children: getTimeOfDayTitle(timeOfDay) }) }), _jsx("span", { className: 'room_container', children: _jsx("span", { className: 'break', children: timeOfDay === 'morning' ? _jsx("span", { children: "0" }) : _jsx("span", { children: "0" }) }) }), _jsx("span", { className: 'buttonbox' })] }, timeOfDay));
        }
        return (_jsxs("div", { className: "cardbox", children: [_jsx("div", { className: "titlebox", children: _jsx("span", { className: 'title_text', children: getTimeOfDayTitle(timeOfDay) }) }), _jsxs("div", { children: [rooms.map(renderRoom), _jsx("span", { className: 'buttonbox' })] })] }, timeOfDay));
    };
    return (_jsxs("div", { className: "teacherUI", children: [_jsx("div", { className: "menubox", children: _jsx("select", { value: selectedTimeOfDay, onChange: (e) => setSelectedTimeOfDay(e.target.value), className: "dropdown_selection dropdown_selection_button", children: timeOfDayOptions.map(option => (_jsx("option", { value: option.value, children: option.label }, option.value))) }) }), _jsx("div", { className: "card_group", children: ["morning", "evening"].map(timeOfDay => {
                    if (selectedTimeOfDay !== "all" && timeOfDay !== selectedTimeOfDay)
                        return null;
                    return renderTimeOfDayCard(timeOfDay);
                }) }), !isChatVisible && (_jsx("div", { className: 'bottombox', children: _jsx("button", { title: 'takaisin: ohjauslistat', className: "menubutton_light", onClick: toggleRoomsVisibility, children: _jsx(IoArrowBackCircleSharp, {}) }) }))] }));
};
export default Ohjattavat;
