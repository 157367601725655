import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// myklinik-frontend/src/components/global/myklinikio.tsx
import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styling/universal/global.css';
import '../../styling/universal/myklinikio.css';
const MyKlinikio = () => {
    const navigate = useNavigate();
    const textRef = useRef(null);
    const containerRef = useRef(null);
    const [shadowWidth, setShadowWidth] = useState(0);
    const SHADOW_OFFSET_LEFT = 0;
    const SHADOW_OFFSET_RIGHT = 30;
    const handleClick = () => {
        navigate("/login");
    };
    const calculateShadowWidth = () => {
        if (textRef.current && containerRef.current) {
            const textElement = textRef.current;
            const containerElement = containerRef.current;
            const yIndex = textElement.textContent?.indexOf('y') || 0;
            const range = document.createRange();
            const textNode = textElement.firstChild;
            if (textNode) {
                range.setStart(textNode, yIndex);
                range.setEnd(textNode, yIndex + 1);
                const yRect = range.getBoundingClientRect();
                const containerRect = containerElement.getBoundingClientRect();
                const width = (yRect.left - containerRect.left) + SHADOW_OFFSET_RIGHT - SHADOW_OFFSET_LEFT;
                setShadowWidth(Math.max(0, width));
            }
        }
    };
    useEffect(() => {
        calculateShadowWidth();
        const resizeObserver = new ResizeObserver(() => {
            requestAnimationFrame(calculateShadowWidth);
        });
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }
        window.addEventListener('resize', calculateShadowWidth);
        return () => {
            resizeObserver.disconnect();
            window.removeEventListener('resize', calculateShadowWidth);
        };
    }, []);
    return (_jsx("div", { className: "logoUI", children: _jsxs("div", { className: "logobox", ref: containerRef, onClick: handleClick, children: [_jsx("div", { className: "logotext", ref: textRef, children: "myklinik.io" }), _jsx("div", { className: "shadow_y" }), _jsx("div", { className: "shadow_y2" }), _jsx("div", { className: "shadow_x" })] }) }));
};
export default MyKlinikio;
